import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { unionBy } from "lodash";

import { IExtendedColumn, IFilter } from "core/shared/interfaces";
import { UnitServiceCodes } from "core/shared/unit/types";

export interface ITableConfig {
  columns?: IExtendedColumn[];
  filters?: IFilter[];
  isGrouped?: boolean;
}

interface ITasksState {
  tableConfigId: number;
  tableConfig: ITableConfig;
}

const initialState: ITasksState = {
  tableConfigId: 0,
  tableConfig: {
    isGrouped: false,
    filters: [
      {
        key: "state_id__in",
        label: "СТАТУС В СИСТ.",
        value: null,
      },
    ],
    columns: [
      {
        id: "order",
        name: "№",
        enabled: true,
        canBeFiltered: false,
        filterKey: "",
        canBeHidden: false,
        width: 50,
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_id",
        name: "ЗАЯВКА",
        enabled: true,
        canBeFiltered: true,
        filterKey: "request_id__contains",
        canBeHidden: false,
        width: 105,
        maxWidth: 320,
        minWidth: 105,
        className: "general",
        sorter: {
          multiple: 1,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "number",
        name: "НОМЕР ЗАДАЧИ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "number__contains",
        canBeHidden: false,
        width: 130,
        className: "general",
        sorter: {
          multiple: 1,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_creation_datetime",
        name: "ДАТА",
        enabled: true,
        canBeFiltered: false,
        canBeHidden: true,
        width: 85,
        className: "general",
        sorter: {
          multiple: 2,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_status",
        name: "СТАТУС",
        enabled: true,
        canBeFiltered: true,
        filterKey: "request_status__in",
        canBeHidden: true,
        width: 150,
        className: "general",
        sorter: {
          multiple: 4,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_equipment",
        name: "ОБОРУДОВАНИЕ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "request_equipment",
        canBeHidden: true,
        width: 130,
        className: "general",
        sorter: {
          multiple: 5,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "request_priority",
        name: "ПРИОРИТЕТ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "request_priority__in",
        canBeHidden: true,
        width: 100,
        className: "general",
        sorter: {
          multiple: 6,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_description",
        name: "ОПИСАНИЕ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "request_description__contains",
        canBeHidden: true,
        width: 90,
        maxWidth: 300,
        minWidth: 90,
        className: "general",
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_comment",
        name: "КОММЕНТАРИЙ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "request_comment__contains",
        canBeHidden: true,
        width: 110,
        minWidth: 110,
        maxWidth: 300,
        className: "general",
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "itsm_case__case_service_offering__name",
        name: "УСЛУГА",
        enabled: true,
        canBeFiltered: true,
        filterKey: "case_service_offering",
        canBeHidden: true,
        width: 175,
        className: "general",
        sorter: {
          multiple: 20,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF, UnitServiceCodes.OOSSA],
      },
      {
        id: "suspended_until",
        name: "ПРИОСТАНОВЛЕНО ДО",
        enabled: true,
        canBeFiltered: false,
        filterKey: "suspended_until",
        canBeHidden: true,
        width: 175,
        className: "general",
        sorter: {
          multiple: 20,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_work_time_usage_percent",
        name: "% ИСП. ВР.",
        enabled: true,
        canBeFiltered: false,
        canBeHidden: true,
        width: 95,
        className: "general",
        sorter: {
          multiple: 8,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "work_time_usage_hours",
        name: "ОСТАТОК",
        enabled: true,
        canBeFiltered: false,
        canBeHidden: true,
        width: 80,
        className: "general",
        sorter: {
          multiple: 8,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "request_has_backlog",
        name: "BACKLOG",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        width: 90,
        className: "general",
        sorter: {
          multiple: 9,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "type",
        name: "ТИП З.",
        filterKey: "type__in",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        width: 70,
        className: "extended",
        sorter: {
          multiple: 10,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "unit",
        name: "УЧАСТОК",
        enabled: true,
        canBeFiltered: false,
        canBeHidden: false,
        width: 220,
        className: "extended",
        services: [UnitServiceCodes.SLPI_KF, UnitServiceCodes.SLPI_ZF],
      },
      {
        id: "is_mounted",
        name: "НАВЕС. / СТАЦИОН.",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        width: 155,
        className: "extended",
        sorter: {
          multiple: 11,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "location__name",
        name: "МЕСТОПОЛОЖ.",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "location__in",
        width: 140,
        className: "extended",
        sorter: {
          multiple: 12,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "location__name",
        name: "ЛОКАЦИЯ",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "location__in",
        width: 140,
        className: "extended",
        sorter: {
          multiple: 12,
        },
        services: [UnitServiceCodes.OOSSA],
      },
      {
        id: "customer_department",
        name: "ПОДРАЗДЕЛЕНИЕ",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "customer_department__in",
        width: 240,
        className: "extended",
        services: [UnitServiceCodes.SLPI_KF, UnitServiceCodes.SLPI_ZF],
        sorter: {
          multiple: 13,
        },
      },
      {
        id: "customer_department",
        name: "БАЛАНСОДЕРЖАТЕЛЬ",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "customer_department__in",
        width: 240,
        className: "extended",
        services: [UnitServiceCodes.OOSSA],
        sorter: {
          multiple: 13,
        },
      },
      {
        id: "object__inventory_number",
        name: "ОБЪЕКТ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "object",
        canBeHidden: true,
        width: 240,
        className: "extended",
        services: [UnitServiceCodes.SLPI_KF, UnitServiceCodes.SLPI_ZF],
        sorter: {
          multiple: 13,
        },
      },
      {
        id: "customer",
        name: "ЗАКАЗЧИК",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        width: 240,
        className: "extended",
        sorter: {
          multiple: 21,
        },
        services: [UnitServiceCodes.SLPI_KF, UnitServiceCodes.SLPI_ZF],
      },
      {
        id: "horizon__name",
        name: "ГОРИЗОНТ",
        enabled: true,
        canBeFiltered: false,
        canBeHidden: true,
        width: 155,
        className: "extended",
        sorter: {
          multiple: 13,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "system__name",
        name: "НАИМ. СИСТЕМЫ",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "system__in",
        width: 160,
        className: "extended",
        sorter: {
          multiple: 14,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "system_complex__inventory_number",
        name: "СИСТЕМЫ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "system_slpi",
        canBeHidden: true,
        width: 160,
        className: "extended",
        sorter: {
          multiple: 14,
        },
        services: [UnitServiceCodes.SLPI_KF, UnitServiceCodes.SLPI_ZF],
      },
      {
        id: "equipment_type__name",
        name: "ТИП ОБОРУД.",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "equipment_type__in",
        width: 160,
        className: "extended",
        sorter: {
          multiple: 15,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "equipment__name",
        name: "ЕД. ОБОРУД.",
        enabled: true,
        canBeFiltered: true,
        filterKey: "equipment__icontains",
        canBeHidden: true,
        width: 240,
        className: "extended",
        sorter: {
          multiple: 16,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "transport__name",
        name: "ТРАНСПОРТ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "transport",
        canBeHidden: true,
        width: 240,
        className: "extended",
        sorter: {
          multiple: 17,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "work_type__name",
        name: "ВИД РАБОТ",
        enabled: true,
        canBeFiltered: true,
        filterKey: "work_type",
        canBeHidden: true,
        width: 120,
        className: "extended",
        sorter: {
          multiple: 17,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
        ],
      },
      {
        id: "malfunction",
        name: "НЕИСПРАВНОСТЬ",
        enabled: true,
        canBeFiltered: false,
        filterKey: "malfunction",
        canBeHidden: true,
        width: 240,
        className: "extended",
        sorter: {
          multiple: 17,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "oossa_object",
        name: "ОБЪЕКТ",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        width: 240,
        className: "extended",
        services: [UnitServiceCodes.OOSSA],
        sorter: {
          multiple: 14,
        },
      },
      {
        id: "system__name",
        name: "СИСТЕМА",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "system__in",
        width: 160,
        className: "extended",
        services: [UnitServiceCodes.OOSSA],
        sorter: {
          multiple: 15,
        },
      },
      {
        id: "oossa_tech_node__name",
        name: "ТЕХ.УЗЕЛ.",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        filterKey: "oossa_tech_node__in",
        width: 140,
        className: "extended",
        sorter: {
          multiple: 12,
        },
        services: [UnitServiceCodes.OOSSA],
      },
      {
        id: "oossa_equipment",
        name: "ЕД. ОБОРУД.",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: true,
        width: 240,
        className: "extended",
        sorter: {
          multiple: 16,
        },
        services: [UnitServiceCodes.OOSSA],
      },
      {
        id: "weight",
        name: "ВЕС",
        enabled: true,
        canBeFiltered: false,
        filterKey: "weight",
        canBeHidden: true,
        width: 70,
        className: "plan",
        sorter: {
          multiple: 18,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "state__name",
        name: "СТАТУС В СИСТ.",
        enabled: true,
        canBeFiltered: true,
        filterKey: "state_id__in",
        canBeHidden: false,
        width: 130,
        className: "plan",
        sorter: {
          multiple: 19,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "deadline",
        name: "ПЛАН. ДАТА",
        enabled: true,
        canBeFiltered: false,
        filterKey: "deadline",
        canBeHidden: false,
        width: 105,
        className: "plan",
        sorter: {
          multiple: 20,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "effort",
        name: "ТРУДОЗАТР.",
        enabled: true,
        canBeFiltered: false,
        filterKey: "effort",
        canBeHidden: false,
        width: 100,
        className: "plan",
        sorter: {
          multiple: 21,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "planned_date",
        name: "В СМЕННОЕ ЗАДАН.",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: false,
        width: 150,
        className: "plan",
        sorter: {
          multiple: 22,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "shift_number",
        filterKey: "shift_number__in",
        name: "ВЫБОР СМЕНЫ",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: false,
        width: 135,
        className: "plan",
        sorter: {
          multiple: 23,
        },
        services: [UnitServiceCodes.PSPA_KF, UnitServiceCodes.PSPA_ZF],
      },
      {
        id: "team__team_number",
        name: "ВЫБОР БРИГАДЫ",
        enabled: true,
        canBeFiltered: false,
        canBeHidden: false,
        width: 150,
        className: "plan",
        sorter: {
          multiple: 24,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
      {
        id: "closing_date",
        name: "ФАКТ. ДАТА",
        enabled: true,
        canBeFiltered: true,
        canBeHidden: false,
        width: 110,
        className: "plan",
        sorter: {
          multiple: 25,
        },
        services: [
          UnitServiceCodes.PSPA_KF,
          UnitServiceCodes.PSPA_ZF,
          UnitServiceCodes.SLPI_KF,
          UnitServiceCodes.SLPI_ZF,
          UnitServiceCodes.OOSSA,
        ],
      },
    ],
  },
};

export const TasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setColumns(state, action) {
      state.tableConfig.columns = unionBy(action.payload, state.tableConfig.columns, "id");
    },
    toggleColumn(state, action) {
      if (!state.tableConfig.columns) {
        return;
      }
      const index = state.tableConfig.columns?.findIndex((column) => column.id === action.payload.id);
      const column = state.tableConfig.columns[index];
      state.tableConfig.columns[index] = {
        ...column,
        enabled: !column.enabled,
      };
    },
    setFilters(state, action) {
      state.tableConfig.filters = action.payload;
    },
    toggleIsGrouped(state) {
      state.tableConfig.isGrouped = !state.tableConfig.isGrouped;
    },
    toggleColumnWidth(state, action) {
      if (!state.tableConfig.columns) {
        return;
      }
      const index = state.tableConfig.columns.findIndex((column) => column.id === action.payload);
      if (index === -1) {
        return;
      }
      const column = state.tableConfig.columns[index];
      if (column.width === column.maxWidth && column.minWidth !== undefined) {
        state.tableConfig.columns[index] = {
          ...column,
          width: column.minWidth,
        };
      }
      if (column.width === column.minWidth && column.maxWidth !== undefined) {
        state.tableConfig.columns[index] = {
          ...column,
          width: column.maxWidth,
        };
      }
    },
    setMaxColumnWidth(state, action) {
      if (!state.tableConfig.columns) {
        return;
      }
      const index = state.tableConfig.columns.findIndex((column) => column.id === action.payload);
      if (index === -1) {
        return;
      }
      const column = state.tableConfig.columns[index];
      if (column.maxWidth) {
        state.tableConfig.columns[index] = {
          ...column,
          width: column.maxWidth,
        };
      }
    },
    setMinColumnWidth(state, action) {
      if (!state.tableConfig.columns) {
        return;
      }
      const index = state.tableConfig.columns.findIndex((column) => column.id === action.payload);
      if (index === -1) {
        return;
      }
      const column = state.tableConfig.columns[index];
      if (column.minWidth) {
        state.tableConfig.columns[index] = {
          ...column,
          width: column.minWidth,
        };
      }
    },
    reset() {
      return initialState;
    },
    resetFilters(state) {
      state.tableConfig.filters = [
        {
          label: undefined,
          value: undefined,
          key: "empty",
        },
      ];
    },
    resetTableConfig(state) {
      return {
        ...initialState,
        tableConfig: initialState.tableConfig,
        tableConfigId: initialState.tableConfigId,
      };
    },
    setTableConfigId(state, action: PayloadAction<number>) {
      state.tableConfigId = action.payload;
    },
    setTableConfig(state, action: PayloadAction<ITableConfig>) {
      state.tableConfig = action.payload;
    },
  },
});

export const {
  setMinColumnWidth,
  setMaxColumnWidth,
  setColumns,
  toggleColumn,
  setFilters,
  toggleIsGrouped,
  reset,
  resetFilters,
  toggleColumnWidth,
  setTableConfigId,
  resetTableConfig,
  setTableConfig,
} = TasksSlice.actions;
