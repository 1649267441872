import { Form, Modal, message } from "antd";
import { useEffect, useContext } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useGetTaskAttachmentsQuery, useGetTaskStatesQuery, useUpdateEtoStepMutation } from "core/app/api";
import {
  StateNamesEnum,
  IEtoTask,
  EtoTaskStepNameEnum,
  IEtoTaskStep,
  EtoTaskAttachmentCategoryEnum,
} from "core/app/api/interfaces";
import Text from "core/lib/Text/Text";
import { useAppDispatch } from "core/app/store";
import { setCurrentTaskUpdated, setTaskToReset } from "core/app/store/maintenance-attached-slice";
import { setTask } from "core/app/store/task-slice";
import { IDictionaryItem } from "core/shared/interfaces";
import { hasEmptyAttachmentsCategory } from "core/shared/task";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { EmptyAttachmentWarning } from "core/widgets/TaskModals/shared/Media/EmptyAttachmentWarning";
import { useTakeTaskInWork } from "core/shared/task/useTakeTaskInWork";
import { useAssistanceCarMaintenanceContext } from "core/widgets/AssistanceCarTaskModal/hooks/useAssistanceCarMaintenanceContext";

export function useStateButtonsHandlers(task: IEtoTask) {
  const dispatch = useAppDispatch();
  const { data: { results: states } = {} } = useGetTaskStatesQuery("");
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task);
  const { data: attachments } = useGetTaskAttachmentsQuery(task.id);
  const { setShowErrorMessage, setErrorsSteps } = useAssistanceCarMaintenanceContext();
  const [updateStepRequest, updateStepResult] = useUpdateEtoStepMutation();
  const handleTakeInWork = useTakeTaskInWork(task.id);

  const form = Form.useFormInstance();

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      dispatch(setCurrentTaskUpdated(true));
      dispatch(setTask(updateTaskResult.data));
    }
    if (updateTaskResult.isError) {
      message.error("При сохранении заявки произошла ошибка.");
    }
  }, [dispatch, updateTaskResult.data, updateTaskResult.isError, updateTaskResult.isSuccess]);

  const handleSave = async (stepId?: number, taskStateId?: number) => {
    const values = form.getFieldsValue();
    const { steps, ...rest } = values;
    if (!steps) {
      return;
    }
    const arrayOfProms = (steps as IEtoTaskStep[]).map((step) =>
      updateStepRequest({
        step,
        taskId: task.id,
      })
    );
    await Promise.all(arrayOfProms);
    const body = {
      id: task.id,
      ...rest,
    };
    if (stepId) {
      body.current_step_id = stepId;
    }
    if (taskStateId) {
      body.state_id = taskStateId;
    }
    updateTaskRequest(body);
  };

  const handleFinishETO = async () => {
    setShowErrorMessage(false);
    form
      .validateFields()
      .then(() => {
        const newStep = task.steps.find((step) => step.step_name === EtoTaskStepNameEnum.POST_SHIFT_CHECK);
        handleSave(newStep?.id).then();
      })
      .catch((err) => {
        const steps = err.values.steps as IEtoTaskStep[];
        const errorState: Record<EtoTaskStepNameEnum, number> = {
          PERFORMING_ETO: 0,
          PRE_SHIFT_CHECK: 0,
          POST_SHIFT_CHECK: 0,
        };
        steps.forEach((step) => {
          let countError = 0;
          if (!step) return;
          step.checklist_json.forEach((checklist) => {
            countError += checklist.checks.reduce((acc, currentValue) => {
              if (currentValue.value === null) return acc + 1;
              return acc;
            }, 0);
          });
          errorState[step.step_name] = countError;
        });
        setErrorsSteps(errorState);
        setShowErrorMessage(true);
      });
  };

  const handleCloseClick = async () => {
    setShowErrorMessage(false);
    const handleOk = async () => {
      const newState = states?.find((state: IDictionaryItem) => state.name === StateNamesEnum.Closed);
      handleSave(undefined, newState?.id);
    };
    try {
      await form.validateFields();
      if (hasEmptyAttachmentsCategory(attachments)) {
        const categories = Object.values(EtoTaskAttachmentCategoryEnum);
        Modal.confirm({
          title: <Text boldness="black">Внимание</Text>,
          icon: <ExclamationCircleOutlined />,
          content: <EmptyAttachmentWarning attachments={attachments} categories={categories} />,
          okText: "Подтвердить",
          cancelText: "Отменить",
          onOk: handleOk,
        });
      } else {
        handleOk();
      }
    } catch (e) {
      setShowErrorMessage(true);
    }
  };

  const handleResetClick = () => {
    if (task) {
      dispatch(setTaskToReset(task.id));
    }
  };

  return {
    handleResetClick,
    handleCloseClick,
    updateTaskResult,
    handleTakeInWork,
    handleFinishETO,
    handleSave,
    updateStepResult,
  };
}
