import { Button, Col, Form, Row, message } from "antd";
import { useEffect, useState } from "react";

import Text from "core/lib/Text/Text";
import { useAppDispatch, useAppSelector } from "core/app/store";
import { setCreateTaskModalType } from "core/app/store/temp-slice";
import { IAttachment, TSendOtherSlpiTaskBody, StateNamesEnum, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useCreateSlpiTaskMutation, useGetTaskStatesQuery } from "core/app/api";
import dayjs, { apiDateFormat, apiDateTimeFormat } from "core/shared/tools/dayjs";
import { IDictionaryItem } from "core/shared/interfaces";
import { useUnit } from "core/shared/unit/useUnit";
import EffortField from "core/widgets/CreateTaskModal/Fields/EffortField";
import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";
import AttachmentsField from "core/widgets/CreateTaskModal/Fields/AttachmentsField";
import ShiftSelector from "core/widgets/CreateTaskModal/Fields/ShiftSelector";
import { taskTypeTitleMap } from "core/widgets/CreateTaskModal";

import { Description } from "../../Other/Slpi/Fields/Description";
import { Customer } from "../../Other/Slpi/Fields/Customer";
import { Department } from "../../Other/Slpi/Fields/Department";
import { Object } from "../../Other/Slpi/Fields/Object";
import { SystemComplex } from "../../Other/Slpi/Fields/SystemComplex";
import { WorkType } from "../../Other/Slpi/Fields/WorkType";
import { ObjectIsMissing } from "../../Other/Slpi/Fields/ObjectIsMissing";

import { MaintenanceType } from "./Fields/MaintenanceType";

export function Slpi() {
  const dispatch = useAppDispatch();
  const unit = useUnit();
  const [unitId] = useUnitId();
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  const [sendTask, sendTaskResult] = useCreateSlpiTaskMutation();
  const { data: states } = useGetTaskStatesQuery("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [form] = Form.useForm();
  const initialValues = {
    customerId: null,
    equipmentTypeId: null,
    equipmentId: null,
    locationId: null,
    description: "",
    attachments: [],
    effort: 0,
    teamId: null,
    shiftNumber: 1 as 1 | 2 | 3,
    plannedDate: "",
    horizonId: null,
    departmentId: null,
    objectId: null,
    systemComplexId: null,
    workTypeId: null,
    objectIsMissing: false,
    monthPlanName: null,
    taskListOrshift: "shift",
  };

  const handleCancel = () => {
    dispatch(setCreateTaskModalType(null));
  };

  const handleSubmit = async (values: typeof initialValues) => {
    if (!unitId || !createTaskModalType || !states) {
      return;
    }
    const state = states.results.find((item: IDictionaryItem) => item.name === StateNamesEnum.New);

    setShowErrorMessage(false);
    const body: TSendOtherSlpiTaskBody = {
      type: TaskTypesIdsEnum.MAINTENANCE,
      state_id: state?.id,
      unit_id: unitId,
      request_description: values.description,
      effort: values.effort,
      customer_id: values.customerId,
      customer_department_id: values.departmentId,
      service_id: unit?.service.id,
      request_creation_datetime: dayjs().format(apiDateTimeFormat),
      object_id: values.objectId,
      system_complex_id: values.systemComplexId,
      work_type_id: values.workTypeId,
    };
    if (values.monthPlanName) {
      body.month_plan_name = values.monthPlanName;
    }
    if (values.equipmentId) {
      body.is_mounted = false;
    }
    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }
    if (values.attachments) {
      body.attachments = {
        files_by_task: values.attachments as IAttachment[],
        work_place: [],
        before_or_in_progress: [],
        after_completion: [],
      };
    }

    try {
      await sendTask(body).unwrap();
      message.success("Заявка создана успешно.");
      handleCancel();
    } catch (e) {
      message.error("При создании заявки произошла ошибка.");
    }
  };

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };

  useEffect(() => {
    form.resetFields();
    setShowErrorMessage(false);
  }, [createTaskModalType, form]);

  return (
    <Form
      className="create-recovery-task-modal__form"
      form={form}
      layout="vertical"
      name="custom-task"
      initialValues={initialValues}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={handleValidationFailed}
    >
      <Row gutter={24}>
        <Col className="mb-3" span={24}>
          {createTaskModalType && (
            <Text boldness="black" size="h4" className="uppercase">
              Создание {taskTypeTitleMap[createTaskModalType]}
            </Text>
          )}
        </Col>
        {showErrorMessage && (
          <Col className="mb-3" span={24}>
            <ErrorMessage />
          </Col>
        )}
        <Col span={12}>
          <Customer />
        </Col>
        <Col span={12}>
          <Department />
        </Col>
        <Col span={12}>
          <Object />
        </Col>
        <Col span={12}>
          <SystemComplex />
        </Col>
        <Col span={12}>
          <MaintenanceType />
        </Col>
        <Col span={12}>
          <EffortField name="effort" required />
        </Col>
        <Col span={12}>
          <ObjectIsMissing />
        </Col>
        <Col span={24}>
          <Description />
        </Col>
        <Col span={24}>
          <ShiftSelector showSelector={false} />
        </Col>
        <Col span={24}>
          <AttachmentsField />
        </Col>
      </Row>
      <Row gutter={32} justify="center" className="mt-4">
        <Col span={12}>
          <Button className="w-100 modal-button" size="large" onClick={handleCancel}>
            ОТМЕНИТЬ
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className="w-100 modal-button"
            size="large"
            type="primary"
            htmlType="submit"
            loading={sendTaskResult.isLoading}
          >
            СОЗДАТЬ
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
