import { ReactElement } from "react";
import { Row, Col, Card } from "antd";

import { useShiftContext, ShiftContext } from "core/shared/dashboard/context/shiftContext";

import LeftCol from "./LeftCol";
import RightCol from "./RightCol";

function Shifts(): ReactElement {
  const shiftContextValue = useShiftContext();

  return (
    <ShiftContext.Provider value={shiftContextValue}>
      <Card className="shifts">
        <Row gutter={24}>
          <Col span={4}>
            <LeftCol />
          </Col>
          <Col span={20}>
            <RightCol />
          </Col>
        </Row>
      </Card>
    </ShiftContext.Provider>
  );
}

export default Shifts;
