import { Row, Col, Empty } from "antd";
import qs from "qs";

import { useGetDashboardMaintenancePlanFactStatisticQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import { TPlanFactStatistic } from "core/app/api/interfaces";

import { Bars } from "../Bars";
import { TablePspa } from "../Table/TablePspa";

export const getStatAmount = (stat: TPlanFactStatistic, isMounted: boolean): number => {
  const amount = stat.find((item) => item.is_mounted === isMounted)?.amount;
  if (amount === undefined) {
    return 0;
  }
  return amount;
};

function PspaMaintenance() {
  const [unitId] = useUnitId();
  const { data, isSuccess } = useGetDashboardMaintenancePlanFactStatisticQuery(
    qs.stringify({
      unit_id: unitId,
    })
  );

  if (!isSuccess) {
    return <Empty />;
  }

  return (
    <Row gutter={60}>
      <Col span={8}>
        <TablePspa data={data} />
      </Col>
      <Col span={8}>
        {data && (
          <Bars
            title="Стационарное ТО"
            month={getStatAmount(data.plans_for_the_month, false)}
            day={getStatAmount(data.plans_for_the_day, false)}
            fact={getStatAmount(data.facts_for_the_day, false)}
          />
        )}
      </Col>
      <Col span={8}>
        {data && (
          <Bars
            title="НАВЕСНОЕ ТО"
            month={getStatAmount(data.plans_for_the_month, true)}
            day={getStatAmount(data.plans_for_the_day, true)}
            fact={getStatAmount(data.facts_for_the_day, true)}
          />
        )}
      </Col>
    </Row>
  );
}

export default PspaMaintenance;
