import { Select, Form } from "antd";
import { ReactElement } from "react";

import { IEmployee } from "core/app/store/employee-slice";
import { useEmployees } from "core/shared/useEmployees";

interface EmployeesSelectProps {
  disabled: boolean;
  teamId?: number | null;
  required?: boolean;
  name?: string;
}

export function EmployeesSelect({
  disabled,
  teamId,
  required,
  name = "maintenance_executor_id",
}: EmployeesSelectProps): ReactElement {
  const { data: employees } = useEmployees({ teamId });

  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (employees?.results) {
    options = employees.results.map((employee: IEmployee) => ({
      label: employee.full_name,
      value: employee.id,
    }));
  }

  return (
    <Form.Item
      required={required}
      name={name}
      label="ЗАКРЫЛ"
      rules={[
        {
          message: "",
          required,
        },
      ]}
    >
      <Select
        disabled={disabled}
        style={{ width: "100%" }}
        showSearch
        placeholder="Выберите сотрудника"
        optionFilterProp="children"
        allowClear
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
      />
    </Form.Item>
  );
}
