import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { TSLPISystemComplex } from "core/app/api/interfaces";
import { useGetSlpiSystemComplexLightQuery } from "core/app/api";
import { getSystemsString } from "core/pages/SLPI/Yearly/SlpiObject/tools";
import { useUnitId } from "core/shared/unit/useUnitId";

export function SystemComplex(): ReactElement {
  const [unitId] = useUnitId();
  const departmentId = Form.useWatch("departmentId");
  const form = Form.useFormInstance();
  const objectId = Form.useWatch("objectId");

  const queryParams: Record<string, string | number> = {};

  if (unitId) {
    queryParams.unit_id = unitId;
  }

  if (departmentId) {
    queryParams.customer_department_id = departmentId;
  }
  if (objectId) {
    queryParams.object_id = objectId;
  }

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetSlpiSystemComplexLightQuery(queryString);

  const options = (data || []).map((item: TSLPISystemComplex) => ({
    label: item.inventory_number
      ? `${getSystemsString(item.systems)} (${item.inventory_number})`
      : getSystemsString(item.systems),
    value: item.id,
  }));

  const handleSelectChange = () => {
    form.setFieldValue("monthPlanName", null);
  };

  return (
    <Form.Item label="КОМПЛЕКС СИСТЕМ" name="systemComplexId" colon={false}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        onChange={handleSelectChange}
        options={options}
      />
    </Form.Item>
  );
}
