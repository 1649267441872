import { createSlice } from "@reduxjs/toolkit";

import { UserPermission } from "core/shared/permissions/interfaces";

interface IUserState {
  role: string | null;
  permissions: Record<string, UserPermission>;
}

const initialState: IUserState = {
  role: null,
  permissions: {},
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setRole(state, action) {
      state.role = action.payload;
    },
  },
});

export const { setRole } = UserSlice.actions;
