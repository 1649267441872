import { ReactElement } from "react";
import plugin from "dayjs/plugin/duration";

import dayjs, { formatHours } from "core/shared/tools/dayjs";
import { isGroup } from "core/shared/dashboard";
import { useUnit } from "core/shared/unit/useUnit";

import { ICellProps } from "..";

function Effort({ record }: ICellProps): ReactElement {
  const unit = useUnit();
  let effort: plugin.Duration;
  if (isGroup(record)) {
    if (record.children?.length) {
      effort = record.children.reduce((duration, row) => duration.add(dayjs.duration(row.effort)), dayjs.duration(0));
      if (unit?.isSLPI()) {
        return <div>{formatHours(effort.asHours())}</div>;
      }
      return <div>{formatHours(effort.asHours())}</div>;
    }
    return <div />;
  }
  effort = dayjs.duration(record.effort);
  if (unit?.isSLPI()) {
    return <div>{formatHours(effort.asHours())}</div>;
  }
  return <div>{formatHours(effort.asHours())}</div>;
}

export default Effort;
