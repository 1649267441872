import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useState, MouseEvent, useCallback } from "react";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Row, Col } from "antd";
import Drawer from "@mui/material/Drawer";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

import nnLogo from "core/images/nn-logo.svg";
import { AppRoutes } from "core/shared/constants.routes";
import { useAppDispatch } from "core/app/store";
import { logOut } from "core/app/store/token-slice";
import { util } from "core/app/api";
import { CanIVisitMenuItem } from "core/shared/components/CanIVisitMenuItem";
import { usePrintTaskReportModalOpen } from "core/shared/tasks/usePrintTaskReportModalOpen";
import { PrintTaskReportModalStates } from "core/app/store/temp-slice";
import { useFullScreen } from "core/shared/main/store/useFullScreen";
import { useUserHasCustomerAnyRole } from "core/shared/main/api/useUserRoles";
import { CanIView } from "core/shared/components/CanIView";
import { Role } from "core/shared/constants";

import VersionNumber from "./VersionNumber";
import { UserName } from "./UserName";
import { OfflineSelector } from "./OfflineSelector";
import { TasksButton } from "./TasksButton";

function AppBar() {
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useAppDispatch();
  const [, setPrintTaskReportModalState] = usePrintTaskReportModalOpen();
  const [fullScreen] = useFullScreen();

  const handleMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const handleExitClick = useCallback(() => {
    dispatch(logOut());
    dispatch(util.resetApiState());
  }, [dispatch]);

  const container = window !== undefined ? () => document.body : undefined;

  const isUserHasCustomerAnyRole = useUserHasCustomerAnyRole();

  if (fullScreen) {
    return <div />;
  }

  return (
    <>
      <MuiAppBar style={{ zIndex: 600, height: "48px" }}>
        <Toolbar style={{ height: "100%", minHeight: 0 }}>
          <Row style={{ width: "100%" }} justify="space-between">
            <Col>
              <Row align="middle" gutter={32}>
                <Col>
                  <Button className="main-menu" onClick={handleMenuClick} icon={<MenuOutlined />}>
                    МЕНЮ
                  </Button>
                </Col>
                <Col>
                  <NavLink to={AppRoutes.base}>
                    <img src={nnLogo} alt="logo" style={{ verticalAlign: "middle" }} />
                  </NavLink>
                </Col>
              </Row>
            </Col>
            <Col style={{ alignSelf: "center" }}>
              <OfflineSelector />
            </Col>
            <Col>
              <Row gutter={32} align="middle">
                <Col>
                  <TasksButton />
                </Col>
                <Col>
                  <UserName />
                </Col>
                <Col>
                  <Button onClick={handleExitClick} type="text">
                    ВЫЙТИ
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Toolbar>
      </MuiAppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={openMenu}
        onClose={handleMenuClose}
        PaperProps={{ style: { background: "#e2ebf0", padding: "32px 16px" } }}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" onClick={handleMenuClose} icon={<CloseOutlined />}>
            Закрыть
          </Button>
        </div>
        <CanIVisitMenuItem permissionKey="menu/shift_tasks">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h2">Сменное задание</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CanIVisitMenuItem permissionKey="teams">
                <NavLink to={AppRoutes.teams} onClick={handleMenuClose}>
                  <MenuItem>Формирование бригад</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
              <CanIVisitMenuItem permissionKey="tasks">
                <NavLink to={AppRoutes.tasks} onClick={handleMenuClose}>
                  <MenuItem>Формирование наряд задания</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
              <CanIVisitMenuItem permissionKey="tasks_for_teams" name="Распределение задач по бригадам">
                <NavLink to={AppRoutes.tasksForTeams} onClick={handleMenuClose}>
                  <MenuItem>Распределение задач по бригадам</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
              <NavLink to={AppRoutes.taskForShift} onClick={handleMenuClose}>
                <MenuItem>Задачи на смену</MenuItem>
              </NavLink>
            </AccordionDetails>
          </Accordion>
        </CanIVisitMenuItem>
        <CanIVisitMenuItem permissionKey="menu/planning">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h2">Планирование ТО/Персонала</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CanIVisitMenuItem permissionKey="menu/planning/pspa_maintenance">
                <NavLink to={AppRoutes.maintenance} onClick={handleMenuClose}>
                  <MenuItem>Техническое обслуживание ПСПА</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
              <CanIVisitMenuItem permissionKey="menu/planning/slpi_maintenance">
                <NavLink to={AppRoutes.slpi} onClick={handleMenuClose}>
                  <MenuItem>Техническое обслуживание СЛПИ</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
              <CanIVisitMenuItem permissionKey="menu/planning/oossa_maintenance">
                <NavLink to={AppRoutes.oossa} onClick={handleMenuClose}>
                  <MenuItem>Техническое обслуживание ООССА</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
              <CanIVisitMenuItem permissionKey="menu/planning/pss_maintenance">
                <NavLink to={AppRoutes.pss} onClick={handleMenuClose}>
                  <MenuItem>Техническое обслуживание ПСС</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
              <CanIVisitMenuItem permissionKey="menu/planning/employee_schedule">
                <NavLink to={AppRoutes.employeesSchedule} onClick={handleMenuClose}>
                  <MenuItem>График работы персонала</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
            </AccordionDetails>
          </Accordion>
        </CanIVisitMenuItem>
        <CanIVisitMenuItem permissionKey="menu/reports">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h2">Отчётность</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CanIVisitMenuItem permissionKey="menu/reports/statistics">
                <MenuItem onClick={handleMenuClose}>
                  <NavLink to={AppRoutes.base}>Статистика</NavLink>
                </MenuItem>
              </CanIVisitMenuItem>
              <CanIVisitMenuItem permissionKey="menu/reports/team_rating" name="Рейтинг бригад">
                <MenuItem onClick={handleMenuClose}>
                  <NavLink to={AppRoutes.rating}>Рейтинг бригад</NavLink>
                </MenuItem>
              </CanIVisitMenuItem>
              <CanIView
                roles={[Role.RELIABILITY_ENGINEER, Role.PSPA_SERVICE_LEAD, Role.PSPA_WORKSHOP_LEAD, Role.UNIT_LEAD]}
              >
                <MenuItem
                  onClick={() => {
                    setPrintTaskReportModalState(PrintTaskReportModalStates.AnyUnit);
                    handleMenuClose();
                  }}
                >
                  <span style={{ color: "#1890ff" }}>Выгрузка по заявкам для ОПиН</span>
                </MenuItem>
              </CanIView>
              <NavLink to={AppRoutes.equipments} onClick={handleMenuClose}>
                <MenuItem>Карточка оборудования</MenuItem>
              </NavLink>
              <CanIVisitMenuItem permissionKey="menu/reports/daily_report">
                <NavLink to={AppRoutes.dailyReport} onClick={handleMenuClose}>
                  <MenuItem>Отчёт для проведения планерок</MenuItem>
                </NavLink>
              </CanIVisitMenuItem>
            </AccordionDetails>
          </Accordion>
        </CanIVisitMenuItem>
        <CanIVisitMenuItem permissionKey="menu/reference">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h2">Справка</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <NavLink to={AppRoutes.help} onClick={handleMenuClose}>
                <MenuItem style={{ margin: "8px 0" }}>Видеоинструкции</MenuItem>
              </NavLink>
            </AccordionDetails>
          </Accordion>
        </CanIVisitMenuItem>
        <CanIVisitMenuItem permissionKey="menu/dictionary_management">
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h2">Управление справочниками</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <a href="/catalog-admin/" target="_blank" onClick={handleMenuClose}>
                <MenuItem style={{ margin: "8px 0" }}>Справочники</MenuItem>
              </a>
            </AccordionDetails>
          </Accordion>
        </CanIVisitMenuItem>
        <VersionNumber />
      </Drawer>
    </>
  );
}

export { AppBar };
