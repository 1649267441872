import qs from "qs";

import { useGetEmployeesQuery } from "core/app/api";

import { useUnitId } from "../unit/useUnitId";

type EmployeeFilters = {
  teamId?: number | null;
  availability_date?: string;
  availability_shift?: number;
  copy_teams_from_date?: string;
  copy_teams_from_shift_number?: number;
  is_driver?: boolean;
};

export const useEmployees = (filters?: EmployeeFilters, skip?: boolean) => {
  const [unit] = useUnitId();

  const queryParams: Record<string, any> = {
    unit_id: unit,
  };

  if (filters?.teamId !== undefined) {
    queryParams.team_id = filters.teamId;
  }
  if (filters?.availability_date !== undefined) {
    queryParams.availability_date = filters.availability_date;
  }
  if (filters?.availability_shift !== undefined) {
    queryParams.availability_shift = filters.availability_shift;
  }
  if (filters?.copy_teams_from_date) {
    queryParams.copy_teams_from_date = filters.copy_teams_from_date;
  }
  if (filters?.copy_teams_from_shift_number) {
    queryParams.copy_teams_from_shift_number = filters.copy_teams_from_shift_number;
  }
  if (filters?.is_driver !== undefined) {
    queryParams.is_driver = filters.is_driver;
  }

  const employeesQS = qs.stringify(queryParams);
  return useGetEmployeesQuery(employeesQS, { skip });
};
