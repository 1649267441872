import { Form, Checkbox } from "antd";

import { useWorkType } from "core/shared/slpi/useWorkType";
import { SlpiWorkTypeGroupEnum } from "core/app/api/interfaces";

export function ObjectIsMissing() {
  const workTypeId = Form.useWatch("workTypeId");
  const workType = useWorkType(workTypeId);

  if (workType?.group !== SlpiWorkTypeGroupEnum.RESEARCH) {
    return null;
  }

  return (
    <Form.Item valuePropName="checked" label="ОБЪЕКТ ОТСУТСТВУЕТ В СПИСКЕ" name="objectIsMissing">
      <Checkbox />
    </Form.Item>
  );
}
