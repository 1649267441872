import { Row, Col } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import {
  InventoryNumber,
  ProjectNumber,
  SlpiMaintenanceName,
  Object,
  Customer,
  Department,
  Unit,
  AccumulatorElapsedTime,
} from "core/widgets/TaskModals/shared/Fields";
import { Field } from "core/widgets/TaskModals/shared/Fields/Field";
import { formatHours } from "core/shared/main/tools/formatHours";

interface SLPIGeneralInfoProps {
  task: ITask;
}

function SLPIGeneralInfo(props: SLPIGeneralInfoProps): ReactElement {
  const { task } = props;

  return (
    <Row className="mb-1">
      <Col>
        <InventoryNumber task={task} />
        <ProjectNumber task={task} />
        <SlpiMaintenanceName task={task} />
        <Object task={task} />
        <Customer task={task} />
        <Department task={task} />
        <Unit task={task} />
        <AccumulatorElapsedTime task={task} />
        <Field label="Плановые трудозатраты на смену" value={formatHours(task.effort ?? 0)} />
        <Field label="Плановые трудозатраты по системе" value={formatHours(task.effort_by_system_complex ?? 0)} />
      </Col>
    </Row>
  );
}

export default SLPIGeneralInfo;
