import { ReactElement } from "react";
import { Space } from "antd";

import { useUnit } from "core/shared/unit/useUnit";

import { ByTeamPspaTable } from "./ByTeamPspaTable";
import { ByTeamSlpiTable } from "./ByTeamSlpiTable";
import { ByTeamOossaTable } from "./ByTeamOossaTable";
import TaskLegend from "./TaskLegend";

function ByTeam(): ReactElement {
  const unit = useUnit();

  return (
    <Space direction="vertical" size={16}>
      <TaskLegend />
      {unit?.isPSPA() && <ByTeamPspaTable />}
      {unit?.isSLPI() && <ByTeamSlpiTable />}
      {unit?.isOOSSA() && <ByTeamOossaTable />}
    </Space>
  );
}

export default ByTeam;
