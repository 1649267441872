import { ReactElement, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import qs from "qs";
import { Form } from "antd";

import { useUnit } from "core/shared/unit/useUnit";
import { useGetDashboardSlpiMaintenancePlanFactStatisticQuery } from "core/app/api";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function Bars(): ReactElement {
  const unit = useUnit();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const yearDate = Form.useWatch("year");
  const monthDate = Form.useWatch("month");
  const customerId = Form.useWatch("customer_id");
  const customerDepartmentId = Form.useWatch("customer_department_id");

  const queryParams: Record<string, any> = {
    unit_id: unit?.id,
  };

  if (yearDate) {
    queryParams.year = yearDate.year();
  }
  if (monthDate) {
    queryParams.month = monthDate.month() + 1;
  }
  if (customerId) {
    queryParams.customer_id = customerId;
  }
  if (customerDepartmentId) {
    queryParams.customer_department_id = customerDepartmentId;
  }

  const showCurrentDateFields = yearDate?.year() === new Date().getFullYear();

  const { data } = useGetDashboardSlpiMaintenancePlanFactStatisticQuery(qs.stringify(queryParams));

  const options: ChartOptions<"bar"> = {
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  let labels = [];
  let backgroundColors = [];

  if (showCurrentDateFields) {
    labels = ["Месяц", "Тек. дата", "Факт", "Отклонение", "Остаток"];
    backgroundColors = ["#B1DE9C", "#FFCE51", "#DEA4FA", "#FF6442", "#BAD3EA"];
  } else {
    labels = ["Месяц", "Факт", "Остаток"];
    backgroundColors = ["#B1DE9C", "#DEA4FA", "#BAD3EA"];
  }

  let month = 0;
  let day = 0;
  let fact = 0;
  let diff = 0;
  let left = 0;
  if (data) {
    month = data.plans_for_the_month;
    day = data.plans_for_the_day;
    fact = data.facts_for_the_day;
    diff = fact - day;
    left = month - fact;
  }

  let values = [];

  if (showCurrentDateFields) {
    values = [month, day, fact, diff, left];
  } else {
    values = [month, fact, left];
  }

  const barData = {
    labels,
    datasets: [
      {
        label: "Стационарное ТО",
        data: values,
        backgroundColor: backgroundColors,
        labels,
      },
    ],
  };

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = ChartJS.getChart("stationary-slpi");

      if (chart) {
        chart.options.aspectRatio = chartContainerRef.current.clientWidth / chartContainerRef.current.clientHeight;
      }
    }
  }, []);

  return (
    <div
      ref={chartContainerRef}
      style={{
        height: "250px",
        width: "100%",
      }}
    >
      <Bar id="stationary-slpi" options={options} data={barData} />
    </div>
  );
}
