import { IAttachment, ITask, TaskAttachmentCategoryEnum, EtoTaskAttachmentCategoryEnum } from "core/app/api/interfaces";

import { UnitServiceCodes } from "../unit/types";

export function getAttachmentsCategoryCount(
  attachments: IAttachment[] | undefined,
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum
) {
  return attachments?.reduce((accumulator, currentValue) => {
    if (currentValue.category === category) {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);
}

export function hasEmptyAttachmentsCategory(attachments: IAttachment[] | undefined) {
  const categories = Object.values(TaskAttachmentCategoryEnum).filter(
    (category) => category !== TaskAttachmentCategoryEnum.FilesByTask
  );

  for (let index = 0; index < categories.length; index += 1) {
    const category = categories[index];
    if (getAttachmentsCategoryCount(attachments, category) === 0) {
      return true;
    }
  }
  return false;
}

/** Группа файлов (например, фотографий) в карточке ТО. */
export const mediaCategoryTitles = {
  work_place: "МЕСТО РАБОТ",
  before_or_in_progress: "ДО / В ПРОЦЕССЕ",
  after_completion: "ПОСЛЕ",
  files_by_task: "ФАЙЛЫ ПО ЗАДАЧЕ",
  shift_start: "НАЧАЛО СМЕНЫ",
  shift_end: "КОНЕЦ СМЕНЫ",
  other: "ОСТАЛЬНЫЕ",
};

export const isTaskValidForAssignment = (task: ITask): boolean =>
  !!task.request_status || !task.request_category || !task.request_category.required_customer_approval;

export function isSlpiTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodes.SLPI_KF || task?.service?.code === UnitServiceCodes.SLPI_ZF;
}

export function isPspaTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodes.PSPA_KF || task?.service?.code === UnitServiceCodes.PSPA_ZF;
}

export function isOossaTask(task?: ITask) {
  return task?.service?.code === UnitServiceCodes.OOSSA;
}
