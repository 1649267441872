import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EmployeeScheduleDayTypeEnum, IScheduledDay, ITeam } from "core/app/api/interfaces";
import { getShortEmployeeName } from "core/shared/tools";

import { IEmployee } from "../employee-slice";
import { RootState } from "..";

export interface IEmployeeWithScheduleDayType extends IEmployee {
  schedule_day_type: IScheduledDay["scheduled_day"]["schedule_day_type"];
}

export interface ITeamWithModifiedEmployee extends Omit<ITeam, "shift_number" | "moment" | "disbanded"> {
  id: number;
  employees: IEmployeeWithScheduleDayType[];
  shift_number: number;
}

export interface ITeamCopyModalState {
  isOpened: boolean;
  dateTo: Date | null;
  unscheduledEmployees: IEmployeeWithScheduleDayType[];
  employeesToAddIds: number[];
  isEmployeesLoading: boolean;
  teamsToCopy: ITeamWithModifiedEmployee[];
  isTeamsLoading: boolean;
  teamSearchString: string;
  employeeSearchString: string;
}

const initialState: ITeamCopyModalState = {
  isOpened: false,
  dateTo: null,
  teamsToCopy: [],
  isTeamsLoading: true,
  unscheduledEmployees: [],
  employeesToAddIds: [],
  isEmployeesLoading: true,
  teamSearchString: "",
  employeeSearchString: "",
};

export const TeamCopyModalSlice = createSlice({
  name: "team-copy-modal",
  initialState,
  reducers: {
    resetTeamCopyManager: () => initialState,
    setIsTeamCopyManagerOpened: (state, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    setTeamCopyManagerDateTo: (state, action: PayloadAction<Date>) => {
      state.dateTo = action.payload;
    },
    setTeamsToCopy: (state, action: PayloadAction<ITeamCopyModalState["teamsToCopy"]>) => {
      state.teamsToCopy = action.payload || [];
    },
    setIsTeamsToCopyLoading: (state, action: PayloadAction<boolean>) => {
      state.isTeamsLoading = action.payload;
    },
    setUnscheduledEmployees: (state, action: PayloadAction<ITeamCopyModalState["unscheduledEmployees"]>) => {
      state.unscheduledEmployees = action.payload || [];
    },
    setIsUnscheduledEmployeesLoading: (state, action: PayloadAction<boolean>) => {
      state.isEmployeesLoading = action.payload;
    },
    setEmployeeChecked: (state, action: PayloadAction<{ checked: boolean; id: number }>) => {
      const { checked, id } = action.payload;

      if (checked) {
        state.employeesToAddIds.push(id);
      } else {
        state.employeesToAddIds = state.employeesToAddIds.filter((employeeId) => employeeId !== id);
      }
    },
    setTeamSearchString: (state, action: PayloadAction<string>) => {
      state.teamSearchString = action.payload;
    },
    setEmployeeSearchString: (state, action: PayloadAction<string>) => {
      state.employeeSearchString = action.payload;
    },
    addSelectedEmployeesToTeam: (state, action: PayloadAction<{ teamId: number }>) => {
      const { teamId } = action.payload;
      const selectedTeam = state.teamsToCopy.find((team) => team.id === teamId);

      if (selectedTeam) {
        const employeesToCopy = state.unscheduledEmployees.filter(({ id }) => state.employeesToAddIds.includes(id));

        if (!selectedTeam.employees) {
          selectedTeam.employees = [];
        }

        // Фильтрация сотрудников, уже находящихся в выбранной бригаде
        const filteredEmployeesToCopy = employeesToCopy.filter(
          (employeeToAdd) => !selectedTeam.employees.some((teamEmployee) => teamEmployee.id === employeeToAdd.id)
        );

        if (!selectedTeam.team_lead_id) {
          // Если среди добавляемых сотрудников несколько бригадиров - выбираем первого из них
          // У остальных сбрасываем флаг бригадира
          let canBeForeman = true;
          filteredEmployeesToCopy.forEach((employee) => {
            if (employee.is_foreman && canBeForeman) {
              selectedTeam.team_lead_id = employee.id;
              selectedTeam.team_lead_short_name = getShortEmployeeName(employee);
              canBeForeman = false;
            }
          });
        }

        selectedTeam.employees.push(...filteredEmployeesToCopy);

        state.unscheduledEmployees = state.unscheduledEmployees.filter(
          ({ id }) => !state.employeesToAddIds.includes(id)
        );
        state.employeesToAddIds = [];
      }
    },
    removeEmployeeFromTeam: (state, action: PayloadAction<{ teamId: number; employeeId: number }>) => {
      const { teamId, employeeId } = action.payload;
      const selectedTeam = state.teamsToCopy.find((team) => team.id === teamId);

      if (selectedTeam?.employees) {
        const employeeToRemove = selectedTeam.employees.find(({ id }) => id === employeeId);

        if (employeeToRemove) {
          if (selectedTeam.team_lead_id === employeeToRemove.id) {
            selectedTeam.team_lead_id = null;
            selectedTeam.team_lead_short_name = null;
          }
          selectedTeam.employees = selectedTeam.employees.filter(({ id }) => id !== employeeId);

          const isEmployeeToRemoveHasWorkingDay =
            employeeToRemove.schedule_day_type.code === EmployeeScheduleDayTypeEnum.WORKING_DAY;
          const isEmployeeToRemoveAlreadyExists = state.unscheduledEmployees.some(
            (employee) => employee.id === employeeToRemove.id
          );

          if (isEmployeeToRemoveHasWorkingDay && !isEmployeeToRemoveAlreadyExists) {
            state.unscheduledEmployees.push(employeeToRemove);
          }
        }
      }
    },
    setTeamForeman: (state, action: PayloadAction<{ teamId: number; employeeId: number }>) => {
      const { teamId, employeeId } = action.payload;
      const selectedTeam = state.teamsToCopy.find((team) => team.id === teamId);

      if (selectedTeam?.employees) {
        const newForemanEmployee = selectedTeam.employees.find(({ id }) => id === employeeId);

        if (newForemanEmployee) {
          selectedTeam.team_lead_id = employeeId;
          selectedTeam.team_lead_short_name = getShortEmployeeName(newForemanEmployee);
        }
      }
    },
  },
});

export const {
  setTeamCopyManagerDateTo,
  setTeamsToCopy,
  setUnscheduledEmployees,
  setTeamSearchString,
  setEmployeeSearchString,
  setEmployeeChecked,
  setIsTeamCopyManagerOpened,
  addSelectedEmployeesToTeam,
  removeEmployeeFromTeam,
  setIsTeamsToCopyLoading,
  setIsUnscheduledEmployeesLoading,
  setTeamForeman,
  resetTeamCopyManager,
} = TeamCopyModalSlice.actions;

export const selectTeamCopyModalIsOpened = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.isOpened
);

export const selectTeamCopyModalDateTo = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.dateTo
);

export const selectTeamsToCopy = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.teamsToCopy
);

export const selectIsTeamsToCopyLoading = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.isTeamsLoading
);

export const selectUnscheduledEmployees = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.unscheduledEmployees
);

export const selectIsUnscheduledEmployeesLoading = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.isEmployeesLoading
);

export const selectEmployeesToAddIds = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.employeesToAddIds
);

export const selectTeamSearchString = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.teamSearchString
);

export const selectEmployeeSearchString = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state.employeeSearchString
);

export const selectTeamCopyModalValues = createSelector(
  (state: RootState) => state.teamCopyModal,
  (state) => state
);
