import { ReactElement } from "react";
import { Row, Col } from "antd";

import {
  ITask,
  TaskAttachmentCategoryEnum,
  EtoTaskAttachmentCategoryEnum,
  ShiftPlanStatusesEnum,
  IEtoTask,
} from "core/app/api/interfaces";
import { useGetTaskAttachmentsQuery } from "core/app/api";
import { useShiftPlan } from "core/shared/shift/useShiftPlan";

import { MediaItem } from "./MediaItem";
import SelectMediaSource from "./SelectMediaSource";

interface MediaProps {
  task: ITask | IEtoTask;
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum;
}

function Сategory({ task, category }: MediaProps): ReactElement {
  const { data } = useGetTaskAttachmentsQuery(task.id);
  const shiftPlan = useShiftPlan();

  let disabled = true;
  if (shiftPlan?.status === ShiftPlanStatusesEnum.APPROVED && category !== TaskAttachmentCategoryEnum.FilesByTask) {
    disabled = false;
  }

  return (
    <Row gutter={16}>
      {!disabled && (
        <Col span={3} className="mb-7" sm={3} xs={6}>
          <div className="media-upload">
            <SelectMediaSource taskId={task.id} category={category} />
          </div>
        </Col>
        // <UploadMedia taskId={task.id} category={category} />
      )}
      {data
        ?.filter((attachment) => attachment.category === category)
        .map((attachment) => (
          <Col span={3} key={attachment.id} className="picture-card-wrapper mb-7" sm={3} xs={6}>
            <MediaItem taskId={task.id} attachment={attachment} disabled={disabled} />
          </Col>
        ))}
    </Row>
  );
}

export { Сategory };
