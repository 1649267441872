import { useGetUiPermissionsQuery } from "core/app/api/api";
import { IUiPermission } from "core/app/api/interfaces";

import { UiPermissionKey, UiPermissionsType } from "../interfaces";

function useUiPermissions<T extends UiPermissionKey>(permissionKey: T): UiPermissionsType<T> {
  const { data } = useGetUiPermissionsQuery(``);

  if (data) {
    let permissions: IUiPermission[] = data;

    if (permissionKey) {
      permissions = data.filter(
        (permission) => permission.key === permissionKey || permission.key.indexOf(`${permissionKey}/`) === 0
      );
    }

    const permissionsMap = permissions.reduce((result, permission) => {
      const pathSegments = permission.key.split("/");
      const permissionLocalKey = pathSegments[pathSegments.length - 1];

      result[permissionLocalKey] = permission.is_allowed;
      return result;
    }, {} as Record<string, boolean>);

    return permissionsMap as UiPermissionsType<T>;
  }

  return {} as UiPermissionsType<T>;
}

export default useUiPermissions;
