import { Row, Col, Popover, Tooltip } from "antd";
import { ReactElement, useState } from "react";
import clsx from "clsx";

import Text from "core/lib/Text/Text";
import { IMaintenanceExecutionCheckListItem } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";

import MaintenanceExecutionCheckMenu from "./MaintenanceExecutionCheckMenu";

/** Статус выполнения работы из чек-листа задания на ТО. */
export enum CheckStatus {
  Unchecked = 0,
  Ok = 1,
  Broken = 2,
  Repaired = 3,
}

interface Props {
  show: boolean;
  value?: IMaintenanceExecutionCheckListItem;
  onChange?: (value: IMaintenanceExecutionCheckListItem) => void;
}

/** Элемент чек-листа задания на ТО: наименование работы, текущий статус, всплывающий выбор статуса выполнения работы. */
export function CheckListItem(props: Props): ReactElement {
  const { show, value, onChange } = props;
  const [visible, setVisible] = useState(false);
  const disabled = useDisabled();

  const handleChange = (newValue: number) => {
    if (typeof onChange === "function" && value) {
      onChange({
        ...value,
        value: newValue,
      });
    }
  };

  const handleClick = () => {
    if (!disabled) {
      setVisible(!visible);
    }
  };

  if (show && value) {
    return (
      <Row key={value.id}>
        <Col span={22}>
          <Tooltip title={value?.check?.description}>
            <Text size="label2" boldness="semibold" className="list-item">
              {value?.check?.title}
            </Text>
          </Tooltip>
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Col>
              <Popover
                open={visible}
                placement="bottom"
                content={<MaintenanceExecutionCheckMenu setVisible={setVisible} onChange={handleChange} />}
                trigger="click"
              >
                {/* marker - квадратик текущего статуса, другие классы придают цвет */}
                <div
                  onClick={handleClick}
                  className={clsx("marker", "small", {
                    disabled,
                    unchecked: value.value === CheckStatus.Unchecked,
                    ok: value.value === CheckStatus.Ok,
                    broken: value.value === CheckStatus.Broken,
                    repaired: value.value === CheckStatus.Repaired,
                  })}
                />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return <div />;
}
