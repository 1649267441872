import type { RcFile } from "antd/es/upload/interface";
import { message } from "antd";

import { checkValidFormatFile } from "./checkValidFormatFile";
// import { imageCompressionHandler } from "./imageCompression";

// добавить флаг compression = true
const fileProcessing = async (file: RcFile | File) => {
  const filePreload: RcFile | File = file;
  const validFile = checkValidFormatFile(file);
  if (!validFile) {
    message.error("Не корректный формат файла");
  }
  // if (file.type.includes("image") && compression) {
  //   filePreload = await imageCompressionHandler(file);
  // }
  const isLessThenLimit = filePreload.size / 1024 / 1024 < 50;
  if (!isLessThenLimit) {
    message.error("Размер загружаемого фала не должен превышать 50МБ!");
  }
  return { isLessThenLimit, validFile, filePreload };
};
export { fileProcessing };
