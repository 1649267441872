import { Row, Col, Divider, Button, Form, InputNumber } from "antd";
import { ReactElement, useEffect, useState } from "react";

import { useAppDispatch } from "core/app/store";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { setCurrentTaskUpdated } from "core/app/store/maintenance-attached-slice";
import Media from "core/widgets/TaskModals/shared/Media";
import { useSignatureStatus } from "core/shared/task/useSignatureStatus";
import { useDisabled } from "core/shared/task/useDisabled";
import { useTask } from "core/shared/task/useTask";
import { ITask } from "core/app/api/interfaces";

import TaskTitle from "../../shared/TaskTitle";
import StateButtons from "../../shared/StateButtons/FormControlled";
import { SignatureButtons } from "../../shared/SignatureButtons";
import { SignatureForm } from "../../shared/SignatureForm";
import { ElapsedTime } from "../../shared/ElapsedTime";
import { EmployeesSelect as EmployeesSelectFormControlled } from "../../shared/EmployeesSelect/FormControlled";
import { Comment } from "../../shared/Comment";
import { ClosingDate } from "../../shared/ClosingDate";

import GeneralInfo from "./GeneralInfo";
import { Tabs } from "./Tabs";

import "../styles.scss";

/** Карточка технического обслуживания (ТО) для навесного оборудования ПСПА. */
function Content(): ReactElement {
  const { data: task } = useTask();
  const [selectedTab, setSelectedTab] = useState<string>(`${task?.maintenance_execution?.[0].id}`);
  const dispatch = useAppDispatch();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task);
  const [signatureStatus] = useSignatureStatus();
  const disabled = useDisabled();
  const [form] = Form.useForm();

  const showTabWithErrors = (errors: any) => {
    const firstError = errors[0];

    if (firstError && firstError.name[0]) {
      const errorPath = (firstError.name[0] as string).split(".");
      const executionId = errorPath[0];

      if (task?.maintenance_execution) {
        const selectedExecution = task.maintenance_execution.find((execution) => execution.id === Number(executionId));

        if (selectedExecution) {
          setSelectedTab(String(selectedExecution.id));
        }
      }
    }
  };

  const handleSave = async (values: Partial<ITask>) => {
    updateTaskRequest(values);
  };

  const handleValidationError = (errorInfo: any) => {
    if (errorInfo.errorFields) {
      showTabWithErrors(errorInfo.errorFields);
    }
  };

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      dispatch(setCurrentTaskUpdated(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateTaskResult.isSuccess]);
  if (task) {
    return (
      <>
        <TaskTitle icon task={task} />
        {!!signatureStatus && <SignatureForm task={task} />}
        {!signatureStatus && (
          <>
            <Form
              form={form}
              initialValues={task}
              scrollToFirstError
              onFinish={handleSave}
              onFinishFailed={handleValidationError}
              layout="vertical"
            >
              <Form.Item name="id" label="id" hidden>
                <InputNumber />
              </Form.Item>
              <GeneralInfo task={task} />
              <StateButtons task={task} onValidationFailed={handleValidationError} />
              <Divider />
              <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              <Divider />
              <Row gutter={16}>
                <Col span={13}>
                  <EmployeesSelectFormControlled disabled={disabled} teamId={task.team_id} />
                  <ElapsedTime disabled={disabled} />
                  <Row>
                    <Col>
                      <ClosingDate value={task.planned_date} />
                    </Col>
                  </Row>
                </Col>
                <Col span={11}>
                  <Comment disabled={disabled} />
                </Col>
              </Row>
            </Form>
            <Media task={task} />
            <SignatureButtons task={task} />
            <Divider />
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  size="large"
                  className="w-100"
                  onClick={form.submit}
                  loading={updateTaskResult.isLoading}
                  disabled={disabled}
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
  return <div />;
}

export default Content;
