import { Tag } from "antd";

import { IDashboardShiftStatisticTasksByTeamsOossaTask } from "core/app/api/interfaces";
import { Group, isGroup } from "core/shared/dashboard";
import { TAG_COLORS } from "core/shared/oossa";

interface CellProps {
  record: IDashboardShiftStatisticTasksByTeamsOossaTask | Group<IDashboardShiftStatisticTasksByTeamsOossaTask>;
}
export function OossaSystem({ record }: CellProps) {
  if (isGroup(record)) {
    return <div />;
  }

  return <Tag color={TAG_COLORS[record.code_enum]}>{record.system}</Tag>;
}
