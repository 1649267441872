import { useLocation } from "react-router-dom";

import { Role } from "core/shared/constants";
import { AppRoutes } from "core/shared/constants.routes";
import { useUnit } from "core/shared/unit/useUnit";
import { useHasUserOneOfSelectedRoles } from "core/shared/main/useHasUserOneOfSelectedRoles";

const permittedRoles = [Role.SLPI_WORKSHOP_LEAD, Role.PSPA_WORKSHOP_LEAD, Role.SHIFT_LEAD, Role.UNIT_LEAD];

/* Проверяет, может ли пользователь менять задачи на странице ФНЗ */
export function useCanChangeTask() {
  const unit = useUnit();
  const location = useLocation();

  const isCorrectLocation = location.pathname === AppRoutes.tasks;

  const isCorrectRole = useHasUserOneOfSelectedRoles(permittedRoles);

  const isCorrectService = unit?.isPSPA();

  return isCorrectLocation && isCorrectRole && isCorrectService;
}
