import { Modal } from "antd";

import { useAppDispatch, useAppSelector } from "core/app/store";
import { setCreateTaskModalType } from "core/app/store/temp-slice";
import { TaskTypesIdsEnum } from "core/app/api/interfaces";

import { RecoveryOrServiceRequest } from "./Forms/RecoveryOrServiceRequest";
import { Other } from "./Forms/Other";
import { ExtraMaintenance } from "./Forms/ExtraMaintenance";

import "./styles.scss";

export const taskTypeTitleMap: Partial<Record<TaskTypesIdsEnum, string>> = {
  [TaskTypesIdsEnum.RECOVERY]: "АВР ЗАЯВКИ",
  [TaskTypesIdsEnum.SERVICE_REQUEST]: "ЗНО ЗАЯВКИ",
  [TaskTypesIdsEnum.OTHER]: "ДОП. ЗАДАЧИ",
  [TaskTypesIdsEnum.MAINTENANCE]: "ДОП. ТО",
};

function CreateTaskModal() {
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  const dispatch = useAppDispatch();
  const handleCancel = () => {
    dispatch(setCreateTaskModalType(null));
  };

  let content = <RecoveryOrServiceRequest />;
  if (createTaskModalType === TaskTypesIdsEnum.OTHER) {
    content = <Other />;
  } else if (createTaskModalType === TaskTypesIdsEnum.MAINTENANCE) {
    content = <ExtraMaintenance />;
  }

  return (
    <Modal
      className="create-task-modal"
      open={!!createTaskModalType}
      onCancel={handleCancel}
      footer={null}
      width={800}
      destroyOnClose
      centered
      maskClosable={false}
    >
      {content}
    </Modal>
  );
}

export default CreateTaskModal;
