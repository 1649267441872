import { ReactNode } from "react";
import { Space } from "antd";

import Accidents from "./Accidents";
import Shifts from "./Shifts";
import Maintenance from "./Maintenance";

function Tab(): ReactNode {
  return (
    <Space direction="vertical" className="w-100" size={16}>
      <Accidents />
      <Shifts />
      <Maintenance />
    </Space>
  );
}

export default Tab;
