import { ReactElement, useContext } from "react";

import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

import { ByEquipmentTypesOrObjects } from "./ByEquipmentTypesOrObjects";
import ByTeam from "./ByTeam";

function Body(): ReactElement {
  const { teamId } = useContext(ShiftContext);

  if (teamId) {
    return <ByTeam />;
  }

  return <ByEquipmentTypesOrObjects />;
}

export default Body;
