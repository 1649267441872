import { ReactElement } from "react";
import { Select, Form } from "antd";

import { IDictionaryItem } from "core/shared/interfaces";
import { useCustomers } from "core/shared/customer/api/useCustomers";
import { useWorkType } from "core/shared/slpi/useWorkType";
import { SlpiWorkTypeGroupEnum } from "core/app/api/interfaces";

export function Customer(): ReactElement {
  const form = Form.useFormInstance();
  const { data, isFetching } = useCustomers();
  const workTypeId = Form.useWatch("workTypeId");
  const workType = useWorkType(workTypeId);
  const objectIsMissing = Form.useWatch("objectIsMissing");

  const options = (data || []).map((item: IDictionaryItem) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (val: number) => {
    form.setFieldValue("departmentId", null);
    form.setFieldValue("systemComplexId", null);
    form.setFieldValue("objectId", null);
  };

  const required =
    workType?.group === SlpiWorkTypeGroupEnum.WORK ||
    (workType?.group === SlpiWorkTypeGroupEnum.RESEARCH && !objectIsMissing);

  return (
    <Form.Item
      label="ЗАКАЗЧИК"
      name="customerId"
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
}
