import decor1 from "core/images/logoDecor1.png";
import decor2 from "core/images/logoDecor2.png";
import decor3 from "core/images/logoDecor3.png";
import decor4 from "core/images/logoDecor4.png";
import decor5 from "core/images/logoDecor5.png";
import decor6 from "core/images/logoDecor6.png";

type Decor = {
  id: number;
  name: string;
  alt: string;
  class: string;
};

const decorList: Decor[] = [
  {
    id: 1,
    name: decor3,
    alt: "decor",
    class: "decor-1",
  },
  {
    id: 2,
    name: decor1,
    alt: "decor",
    class: "decor-2",
  },
  {
    id: 3,
    name: decor2,
    alt: "decor",
    class: "decor-3",
  },
  {
    id: 4,
    name: decor6,
    alt: "decor",
    class: "decor-4",
  },
  {
    id: 5,
    name: decor5,
    alt: "decor",
    class: "decor-5",
  },
  {
    id: 6,
    name: decor4,
    alt: "decor",
    class: "decor-6",
  },
];

export default decorList;
