import { UnitServices, Unit, UnitServiceCodes } from "./types";

export function isSLPI(this: Unit) {
  return this.service.code === UnitServiceCodes.SLPI_KF || this.service.code === UnitServiceCodes.SLPI_ZF;
}

export function isPSPA(this: Unit) {
  return this.service.code === UnitServiceCodes.PSPA_KF || this.service.code === UnitServiceCodes.PSPA_ZF;
}

export function isOOSSA(this: Unit) {
  return this.service.name.includes(UnitServices.OOSSA);
}

export function isLSO(this: Unit) {
  return this.service.name.includes(UnitServices.LSO);
}

export function isLKU(this: Unit) {
  return this.service.code === UnitServiceCodes.LKU;
}

export function isVOLS(this: Unit) {
  return this.service.name.includes(UnitServices.VOLS);
}

export function isPSS(this: Unit) {
  return this.isLSO() || this.isLKU() || this.isVOLS();
}
