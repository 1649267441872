import { permissionGroups } from "../constants";
import { PermissionTreeNode } from "../interfaces";

export function getPermissionDecls() {
  const permissions: Array<{
    key: string;
    description: string;
  }> = [];

  const traverse = function traverse(node: PermissionTreeNode) {
    if (node.permissions) {
      Object.entries(node.permissions).forEach(([key, description]) => {
        permissions.push({
          key: `${node.key}/${key}`,
          description,
        });
      });
    }

    if (node.children) {
      Object.values(node.children).forEach(traverse);
    }
  };

  Object.values(permissionGroups).forEach(traverse);

  return permissions;
}
