import { ReactElement, useEffect } from "react";
import { Form, Select } from "antd";
import qs from "qs";
import { capitalize } from "lodash";

import { ITeam as TeamInterface } from "core/app/api/interfaces";
import { getTeamName } from "core/widgets/TeamName";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat, displayDateFormat } from "core/shared/tools/dayjs";
import { useGetTeamsQuery } from "core/app/api";
import { useUnitShifts } from "core/shared/unit/useUnitShifts";

import { useRequiredCustomerApproval } from "../../hooks";

interface TeamSelectProps {
  required: boolean;
}

function TeamSelect({ required }: TeamSelectProps): ReactElement {
  const shift = Form.useWatch("shiftNumber");
  const date = Form.useWatch("plannedDate");
  const form = Form.useFormInstance();
  const [unitId] = useUnitId();
  const { data: unitShifts } = useUnitShifts();
  const requiredCustomerApproval = useRequiredCustomerApproval();

  let teamsQS: Record<string, string | number | boolean | null> = {
    unit_id: unitId,
  };
  if (required) {
    teamsQS = {
      ...teamsQS,
      date_after: dayjs().startOf("day").add(-1, "day").format(apiDateFormat),
      date_before: dayjs().endOf("day").format(apiDateFormat),
      opened_shift: true,
      suitable_for_me: true,
    };
  } else {
    teamsQS = {
      ...teamsQS,
      date_after: dayjs(date).format(apiDateFormat),
      date_before: dayjs(date).format(apiDateFormat),
      shift_number: shift,
      opened_shift: true,
    };
  }
  const skipRequest = !date || !unitId;
  const { data, isFetching, isSuccess } = useGetTeamsQuery(qs.stringify(teamsQS), { skip: skipRequest });

  useEffect(() => {
    if (date && shift) {
      form.setFieldValue("team", null);
    }
  }, [date, form, shift]);

  useEffect(() => {
    if (isSuccess && !isFetching && data && data[0]) {
      form.setFieldValue("team", data[0].id);
    }
  }, [data, form, isSuccess, isFetching]);

  if (skipRequest || !data) {
    return <div />;
  }

  return (
    <Form.Item label="БРИГАДА" colon={false} name="teamId" style={{ marginBottom: 0 }}>
      <Select
        disabled={!data.length || requiredCustomerApproval}
        placeholder={!data.length ? "Нет бригад" : ""}
        style={{ minWidth: "160px" }}
        size="large"
      >
        {!required && (
          <Select.Option key={null} value={null}>
            <div>Без бригады</div>
          </Select.Option>
        )}
        {data
          .filter((item) => !item.disbanded)
          .map((teamsItem: TeamInterface) => {
            const selectedShift = unitShifts?.find((unitShift) => unitShift.number === teamsItem.shift_number);
            return (
              <Select.Option key={teamsItem.id} value={teamsItem.id}>
                {required ? (
                  <div>
                    {`${dayjs(teamsItem.date).format(displayDateFormat)} | ${capitalize(
                      selectedShift?.name
                    )} | ${getTeamName(teamsItem)} `}
                  </div>
                ) : (
                  getTeamName(teamsItem)
                )}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
}

export default TeamSelect;
