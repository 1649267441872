import { ReactElement } from "react";
import { Card } from "antd";

import { useUnit } from "core/shared/unit/useUnit";

import PspaMaintenance from "./PspaMaintenance";
import SlpiMaintenance from "./SlpiMaintenance";
import { OossaMaintenance } from "./OossaMaintenance";

function Maintenance(): ReactElement {
  const unit = useUnit();

  return (
    <Card className="maintenance">
      {unit?.isPSPA() && <PspaMaintenance />}
      {unit?.isSLPI() && <SlpiMaintenance />}
      {unit?.isOOSSA() && <OossaMaintenance />}
    </Card>
  );
}

export default Maintenance;
