import { ReactElement, useContext } from "react";

import { useGetDashboardShiftStatisticTasksByTeamsSlpiQuery } from "core/app/api";
import { TeamSelector } from "core/shared/dashboard/ui/TeamSelector";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

function TeamSelectorSlpi(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isSuccess } = useGetDashboardShiftStatisticTasksByTeamsSlpiQuery(queryString, { skip: !queryString });

  if (isSuccess && data) {
    return <TeamSelector teams={data} />;
  }
  return <div />;
}

export { TeamSelectorSlpi };
