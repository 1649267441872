/** @jsxImportSource @emotion/react */
import { ReactElement, useEffect } from "react";
import { CSSObject } from "@emotion/react";
import { Button, Row, Col, Form, Input, Alert } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import { isDetailObject } from "core/shared/tools/isDetailObject";
import { IError } from "core/app/api/interfaces";
import { useCreateTokenMutation } from "core/app/api";
import { useAppDispatch } from "core/app/store";
import { setToken } from "core/app/store/token-slice";
import loginBackground from "core/images/backLogo.png";
import pdm from "core/images/pdm3.png";
import { space } from "core/shared/constants";

import "./style.scss";
import LogoDecor from "../../shared/components/LogoDecor";

const styles: Record<string, CSSObject> = {
  page: {
    backgroundImage: `url(${pdm})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    position: "relative",
  },
  button: {
    height: 40,
    width: "100%",
    marginTop: space * 2,
    fontSize: 16,
    fontWeight: 700,
  },
  formWrapper: {
    position: "absolute",
    top: "43%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  alert: {
    marginTop: space,
    marginBottom: space,
  },
};

interface LoginFormValues {
  password: string;
  username: string;
}

function Login(): ReactElement {
  const dispatch = useAppDispatch();
  const [createTokenRequest, createTokenResponse] = useCreateTokenMutation();
  const error = createTokenResponse.error as IError | undefined;
  const screens = useBreakpoint();

  const handleFinish = (values: LoginFormValues) => {
    createTokenRequest(values);
  };

  useEffect(() => {
    if (createTokenResponse.isSuccess) {
      dispatch(setToken(createTokenResponse.data));
    }
  }, [createTokenResponse, createTokenResponse.isSuccess, dispatch]);

  let errorMessage;
  if (error) {
    if (isDetailObject(error.data.detail)) {
      errorMessage = error.data.detail.detail;
    } else {
      errorMessage = error.data.detail;
    }
  }

  return (
    <div css={styles.page}>
      <Row style={{ maxWidth: "700px" }} justify="center">
        <Col sm={16} lg={18} xl={20} xxl={24}>
          <div css={{ position: "relative", padding: "0 5px", zIndex: 10 }}>
            <img style={{ width: "100%", height: "auto" }} src={loginBackground} alt="login" />
            <div css={styles.formWrapper}>
              <Row style={{ maxWidth: "700px" }} justify="center">
                <Form
                  layout="vertical"
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={handleFinish}
                  autoComplete="on"
                  className="form"
                >
                  {errorMessage && <Alert message={errorMessage} type="error" css={styles.alert} showIcon />}
                  <Row style={{ maxWidth: "500px" }} justify="center">
                    <Col xs={14} sm={16} lg={18} xl={20} xxl={24}>
                      <Form.Item
                        label="Логин"
                        name="username"
                        colon={false}
                        rules={[{ required: true, message: "Пожалуйста, введите ваше имя пользователя!" }]}
                        className="form-item"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: "500px" }} justify="center">
                    <Col xs={14} sm={16} lg={18} xl={20} xxl={24}>
                      <Form.Item
                        label="Пароль"
                        name="password"
                        colon={false}
                        rules={[{ required: true, message: "Пожалуйста, введите ваш пароль!" }]}
                        className="form-item"
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ maxWidth: "500px" }} justify="center">
                    <Col xs={14} sm={16} lg={18} xl={20} xxl={24}>
                      <Button
                        loading={createTokenResponse.isLoading}
                        css={styles.button}
                        type="primary"
                        htmlType="submit"
                      >
                        ВОЙТИ
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {screens.lg && <LogoDecor />}
    </div>
  );
}

export default Login;
