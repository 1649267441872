import { Button, Col, Form, Row, message } from "antd";
import { useEffect, useState } from "react";

import Text from "core/lib/Text/Text";
import { useAppDispatch, useAppSelector } from "core/app/store";
import { setCreateTaskModalType } from "core/app/store/temp-slice";
import { ISendCreatioTaskBody, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useSendCreatioTaskMutation } from "core/app/api";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { useUnit } from "core/shared/unit/useUnit";

import TypeSwitchField from "../../Fields/TypeSwitchField";
import EquipmentTypeField from "../Other/Pspa/Fields/EquipmentTypeField";
import LocationField from "../Other/Pspa/Fields/LocationField";
import EffortField from "../../Fields/EffortField";
import EquipmentField from "../Other/Pspa/Fields/EquipmentField";
import RequestCategoryField from "../../Fields/RequestCategoryField";
import DescriptionField from "../../Fields/DescriptionField";
import ErrorMessage from "../../ErrorMessage";
import TransportField from "../Other/Pspa/Fields/TransportField";
import { taskTypeToCategoryGroupFilterMap } from "../../constants";
import { CreateTaskModalValues } from "../../interfaces";
import AttachmentsField from "../../Fields/AttachmentsField";
import ShiftSelector from "../../Fields/ShiftSelector";
import { taskTypeTitleMap } from "../..";
import { useRequiredCustomerApproval } from "../../Fields/hooks";

import CreatioEquipmentField from "./CreatioEquipmentField";

export function RecoveryOrServiceRequest() {
  const dispatch = useAppDispatch();
  const [unitId] = useUnitId();
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  const [sendCreatioTask, sendCreatioTaskResult] = useSendCreatioTaskMutation();
  const unit = useUnit();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [form] = Form.useForm();
  const initialValues: CreateTaskModalValues = {
    isMounted: false,
    isStpp: false,
    requestCategoryId: "",
    creatioEquipmentId: "",
    transportId: null,
    equipmentTypeId: null,
    equipmentId: null,
    locationId: null,
    description: "",
    attachments: [],
    effort: 0,
    teamId: null,
    shiftNumber: 1,
    plannedDate: "",
    taskListOrshift: "taskList",
  };

  const isMounted = Form.useWatch<boolean>("isMounted", form);
  const requiredCustomerApproval = useRequiredCustomerApproval();

  const handleCancel = () => {
    dispatch(setCreateTaskModalType(null));
  };

  const handleSubmit = async (values: typeof initialValues) => {
    if (!unitId || !createTaskModalType) {
      return;
    }
    const type = taskTypeToCategoryGroupFilterMap[createTaskModalType];
    if (!type) {
      return;
    }
    setShowErrorMessage(false);
    const body: ISendCreatioTaskBody = {
      type,
      unit: unitId,
      request_category: values.requestCategoryId,
      equipment_type: values.equipmentTypeId,
      description: values.description,
      location: values.locationId,
    };

    if (values.effort) {
      body.effort = values.effort;
    }
    if (values.effort_duration) {
      body.effort_duration = values.effort_duration;
    }

    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber && !requiredCustomerApproval) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }

    if (values.isStpp && createTaskModalType !== TaskTypesIdsEnum.RECOVERY) {
      body.itsm_stpp_equipment_type = values.creatioEquipmentId;
    } else {
      body.itsm_equipment = values.creatioEquipmentId;
    }

    if (values.isMounted) {
      body.transport = values.transportId;
    } else {
      body.mobile_brigade_equipment = values.equipmentId;
    }

    body.attachments = values.attachments || [];

    try {
      await sendCreatioTask(body).unwrap();
      message.success("Заявка создана успешно.");
      handleCancel();
    } catch (e) {
      message.error("При создании заявки произошла ошибка.");
    }
  };

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };

  useEffect(() => {
    form.resetFields();
    setShowErrorMessage(false);
  }, [createTaskModalType, form]);

  return (
    <Form
      className="create-recovery-task-modal__form"
      form={form}
      layout="vertical"
      name="custom-task"
      style={{ maxWidth: "100%" }}
      initialValues={initialValues}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={handleValidationFailed}
    >
      <Row gutter={24}>
        <Col className="mb-3" span={24}>
          {createTaskModalType && (
            <Text boldness="black" size="h4" className="uppercase">
              Создание {taskTypeTitleMap[createTaskModalType]}
            </Text>
          )}
        </Col>
        <Col className="mb-1" span={24}>
          <TypeSwitchField />
        </Col>
        {showErrorMessage && (
          <Col className="mb-3" span={24}>
            <ErrorMessage />
          </Col>
        )}
        <Col span={12}>
          <RequestCategoryField />
        </Col>
        {isMounted && (
          <Col span={12}>
            <TransportField required />
          </Col>
        )}
        <Col span={12}>
          <CreatioEquipmentField />
        </Col>
        <Col span={12}>
          <EquipmentTypeField required />
        </Col>
        {!isMounted && (
          <Col span={12}>
            <EquipmentField required />
          </Col>
        )}
        <Col span={12}>
          <LocationField required />
        </Col>
        <Col span={12}>
          <EffortField name={unit?.isSLPI() ? "effort" : "effort_duration"} required />
        </Col>
        <Col span={24}>
          <DescriptionField />
        </Col>
        <Col span={24}>
          <AttachmentsField />
        </Col>
        <Col className="mb-1 mt-1" span={24}>
          <ShiftSelector />
        </Col>
      </Row>
      <Row gutter={32} justify="center" className="mt-4">
        <Col span={12}>
          <Button className="w-100 modal-button" size="large" onClick={handleCancel}>
            ОТМЕНИТЬ
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className="w-100 modal-button"
            size="large"
            type="primary"
            htmlType="submit"
            loading={sendCreatioTaskResult.isLoading}
          >
            СОЗДАТЬ
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
