import { Stack } from "@mui/material";
import { ReactElement } from "react";
import { Divider } from "antd";

import PageTitle from "core/widgets/PageTitle";
import { PageTitleContainer } from "core/widgets/PageTitleContainer";
import { UnitsTabs } from "core/widgets/UnitsTabs";

import "./styles.scss";

import Tab from "./Tab";

function Dashboard(): ReactElement {
  return (
    <Stack className="page-dashboard">
      <PageTitleContainer>
        <PageTitle title="СТАТИСТИКА" />
      </PageTitleContainer>
      <Divider />
      <div className="content fixed-min-w">
        <div className="mb-3">
          <UnitsTabs tab={Tab()} />
        </div>
      </div>
    </Stack>
  );
}

export default Dashboard;
