import { createContext } from "react";

import { EtoTaskStepNameEnum } from "core/app/api/interfaces";

import { EtoTaskSignatureSide } from "./AssistanceCarMaintenance/interfaces";

export enum FormPages {
  TASK,
  SIGNATURE,
}

export interface AssistanceCarMaintenanceContextValues {
  page: FormPages;
  setPage: (arg: FormPages) => void;
  showErrorMessage: boolean;
  setShowErrorMessage: (arg: boolean) => void;
  errorsSteps: Record<EtoTaskStepNameEnum, number>;
  setErrorsSteps: (arg: Record<EtoTaskStepNameEnum, number>) => void;
  signatureSide: EtoTaskSignatureSide | null;
  setSignatureSide: (side: EtoTaskSignatureSide | null) => void;
}

export const AssistanceCarMaintenanceContext = createContext<AssistanceCarMaintenanceContextValues>({
  page: FormPages.TASK,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPage: () => {},
  showErrorMessage: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowErrorMessage: () => {},
  errorsSteps: {
    PERFORMING_ETO: 0,
    PRE_SHIFT_CHECK: 0,
    POST_SHIFT_CHECK: 0,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorsSteps: () => {},
  signatureSide: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSignatureSide: () => {},
});
