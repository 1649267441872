import React, { ReactElement } from "react";
import { Row, Col, Alert, Card } from "antd";

import icAlertTiangle from "core/images/ic-alert-tiangle.svg";
import Text from "core/lib/Text/Text";
import { useGetEquipmentQuery } from "core/app/api";

import MaintenanceTable from "./MaintenanceTable";
import CommentsTimeLine from "./CommentsTimeLine";
import MaintenanceSchedule from "./MaintenanceSchedule";
import Unit from "./Unit";
import EquipmentType from "./EquipmentType";
import IsMounted from "./IsMounted";
import EquipmentTypeModel from "./EquipmentTypeModel";

interface EquipmentProps {
  equipmentId: string | number;
}

function Equipment({ equipmentId }: EquipmentProps): ReactElement {
  const { data } = useGetEquipmentQuery(`${equipmentId}`);

  return (
    <Row>
      <Col span={24}>
        <Card bordered={false}>
          {data?.has_malfunction && (
            <Alert
              className="mb-3"
              message="Статус: Неисправно"
              type="warning"
              showIcon
              icon={<img className="ic-alert-triangle" src={icAlertTiangle} alt="" />}
            />
          )}
          <Row className="mb-1">
            <Col>
              <Text size="body1" boldness="black" data-id={data?.id}>
                {data?.name}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Row>
                <Col>
                  <Text size="label2">Номер единицы оборудования:&nbsp;</Text>
                </Col>
                <Col>
                  <Text size="label2" boldness="black" className="blue">
                    01484420
                  </Text>
                </Col>
              </Row>
              {data?.equipment_type_id && <IsMounted equipmentTypeId={data?.equipment_type_id} />}
              {data?.equipment_type_id && <EquipmentType equipmentTypeId={data?.equipment_type_id} />}
              {data?.location?.unit && <Unit unitId={data?.location?.unit} />}
              <Row>
                <Col>
                  <Text size="label2">Местоположение (участок):&nbsp;</Text>
                </Col>
                <Col>
                  <Text size="label2" boldness="black">
                    {data?.location?.name}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text size="label2">Заказчик:&nbsp;</Text>
                </Col>
                <Col>
                  <Text size="label2" boldness="black">
                    ЗФ ПАО &quot;ГМК Норильский никель&quot;
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text size="label2">Категория оборудования:&nbsp;</Text>
                </Col>
                <Col>
                  <Text size="label2" boldness="black">
                    Система
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text size="label2">Дата последней поверки:&nbsp;</Text>
                </Col>
                <Col>
                  <Text size="label2" boldness="black">
                    &nbsp;
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Text size="label2">Плановая дата следующей поверки:&nbsp;</Text>
                </Col>
                <Col>
                  <Text size="label2" boldness="black">
                    &nbsp;
                  </Text>
                </Col>
              </Row>
              {data?.equipment_type_id && (
                <EquipmentTypeModel equipmentTypeId={data?.equipment_type_id} model={data?.equipment_model} />
              )}
            </Col>
            <Col span={14}>
              <MaintenanceSchedule equipmentId={equipmentId} />
            </Col>
          </Row>
        </Card>
        <MaintenanceTable equipmentId={equipmentId} />
        <CommentsTimeLine equipmentId={equipmentId} />
      </Col>
    </Row>
  );
}

export default Equipment;
