import { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";

interface FieldProps {
  label: string;
  value: string | undefined | null;
  valueClassName?: string;
  tooltip?: string;
}

/** Текстовое нередактируемое поле с меткой. */
function Field({ label, value, valueClassName, tooltip }: FieldProps): ReactElement {
  return (
    <Row>
      <Col>
        <Text size="label2" tooltip={tooltip}>
          {label}:&nbsp;
        </Text>
        <Text size="label2" boldness="black" className={valueClassName}>
          {value}
        </Text>
      </Col>
    </Row>
  );
}

export { Field };
