import { intersection } from "lodash";

import { useGetWhoamiQuery } from "core/app/api";
import { Role } from "core/shared/constants";
import { useUnit } from "core/shared/unit/useUnit";

export function useUserRoles() {
  const { data } = useGetWhoamiQuery("");

  return data?.roles || [];
}

export function useUserHasCustomerAnyRole() {
  const roles = useUserRoles();
  return !!intersection(roles, [Role.CUSTOMER_REPRESENTATIVE, Role.CUSTOMER_REPRESENTATIVE_SIGNATORY]).length;
}

export function useAccessUnloadingTasks() {
  const unit = useUnit();
  const roles = useUserRoles();
  const accessSLPI =
    !!intersection(roles, [Role.SLPI_WORKSHOP_LEAD, Role.TEAM_LEAD, Role.RELIABILITY_ENGINEER]).length &&
    unit?.isSLPI();
  const accessPSPA =
    !!intersection(roles, [
      Role.PSPA_WORKSHOP_LEAD,
      Role.SHIFT_LEAD,
      Role.CUSTOMER_REPRESENTATIVE,
      Role.CUSTOMER_REPRESENTATIVE_SIGNATORY,
      Role.UNIT_LEAD,
      Role.RELIABILITY_ENGINEER,
    ]).length && unit?.isPSPA();
  const accessOOSSA =
    !!intersection(roles, [
      Role.SHIFT_LEAD,
      Role.CUSTOMER_REPRESENTATIVE,
      Role.CUSTOMER_REPRESENTATIVE_SIGNATORY,
      Role.UNIT_LEAD,
      Role.RELIABILITY_ENGINEER,
    ]).length && unit?.isOOSSA();
  return { accessSLPI, accessOOSSA, accessPSPA };
}
