import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

/** Атрибут "Вид работ" в карточке ТО. */
function WorkType({ task }: FieldProps): ReactElement {
  return <Field label="Вид работ" value={task.work_type?.name} />;
}

export { WorkType };
