import { useUnit } from "core/shared/unit/useUnit";

import { Pspa } from "./Pspa";
import { Slpi } from "./Slpi";
import { Oossa } from "./Oossa";

export function Other() {
  const unit = useUnit();
  if (unit?.isPSPA()) {
    return <Pspa />;
  }
  if (unit?.isSLPI()) {
    return <Slpi />;
  }
  if (unit?.isOOSSA()) {
    return <Oossa />;
  }
  return <div />;
}
