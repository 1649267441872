import { Form, Divider, Row, Col, Button, InputNumber } from "antd";
import { ReactElement } from "react";

import { useTask } from "core/shared/task/useTask";
import { useSignatureStatus } from "core/shared/task/useSignatureStatus";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { useDisabled } from "core/shared/task/useDisabled";
import { ITask } from "core/app/api/interfaces";

import { SignatureForm } from "../shared/SignatureForm";
import Title from "../shared/TaskTitle";
import { MediaOrComment } from "../Recovery/MediaOrComment";
import StateButtons from "../Recovery/StateButtons";

import GeneralInfo from "./GeneralInfo";
import Body from "./Body";

import "./styles.scss";

/** Задание на доп. работы. */
function Other(): ReactElement {
  const { data: task } = useTask();
  const [form] = Form.useForm();
  const [signatureStatus] = useSignatureStatus();
  const disabled = useDisabled({ skipStatusCheck: true });
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task);

  const handleFinish = (values: Partial<ITask>) => {
    updateTaskRequest(values);
  };

  return (
    <div className="task-modal recovery-task-modal">
      {task && (
        <Form onFinish={handleFinish} form={form} name="TaskCard" layout="vertical" initialValues={task}>
          <Form.Item name="id" label="id" hidden>
            <InputNumber />
          </Form.Item>
          <Title icon task={task} />
          {!!signatureStatus && <SignatureForm task={task} />}
          {!signatureStatus && (
            <>
              <StateButtons task={task} form={form} />
              <GeneralInfo task={task} />
              <Divider />
              <Body form={form} />
              <MediaOrComment />
              <Divider />
              <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w-100"
                    loading={updateTaskResult.isLoading}
                    disabled={disabled}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      )}
    </div>
  );
}

export default Other;
