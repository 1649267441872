import { useMemo } from "react";

import { useGetUnitsQuery } from "core/app/api";
import { Unit } from "core/shared/unit/types";

import { isSLPI, isPSPA, isOOSSA, isLSO, isLKU, isVOLS, isPSS } from "../tools";

const useUnits = (): Unit[] | undefined => {
  const { data } = useGetUnitsQuery("");

  const units = useMemo(() => {
    if (data) {
      return data.map((unit) => ({
        ...unit,
        isSLPI,
        isPSPA,
        isOOSSA,
        isPSS,
        isLKU,
        isLSO,
        isVOLS,
      }));
    }
    return undefined;
  }, [data]);

  return units;
};

export { useUnits };
