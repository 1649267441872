import { Row, Col, Empty } from "antd";
import qs from "qs";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import Text from "core/lib/Text/Text";
import { useUnit } from "core/shared/unit/useUnit";
import { IOossaMaintenancePlanFactStatisticBySystem, OossaSystemTypeEnum } from "core/app/api/interfaces";
import { useGetOossaMaintenancePlanFactStatisticQuery } from "core/app/api";

import { Bars } from "../Bars";
import { TableOossa } from "../Table/TableOossa";

export const getStatAmount = (
  stat: IOossaMaintenancePlanFactStatisticBySystem[],
  system: OossaSystemTypeEnum
): number => {
  const amount = stat.find((item) => item.system === system)?.amount;
  return amount || 0;
};

export function OossaMaintenance() {
  const unit = useUnit();

  const queryString = qs.stringify({
    unit_id: unit?.id,
  });

  const { data, isSuccess } = useGetOossaMaintenancePlanFactStatisticQuery(unit?.isOOSSA() ? queryString : skipToken);

  if (!isSuccess) {
    return <Empty />;
  }

  return (
    <>
      <Row>
        <Col span={24} className="mb-2">
          <Text className="uppercase" boldness="semibold">
            Статистика по ТО
          </Text>
        </Col>
      </Row>
      <Row gutter={60}>
        <Col span={6}>
          <TableOossa data={data} />
        </Col>
        <Col span={6}>
          <Bars
            title="АСКУЭ"
            month={getStatAmount(data.plans_for_the_month, OossaSystemTypeEnum.ASKUE)}
            day={getStatAmount(data.plans_for_the_day, OossaSystemTypeEnum.ASKUE)}
            fact={getStatAmount(data.facts_for_the_day, OossaSystemTypeEnum.ASKUE)}
          />
        </Col>
        <Col span={6}>
          <Bars
            title="АСКУЭ ЭЭ"
            month={getStatAmount(data.plans_for_the_month, OossaSystemTypeEnum.ASKUE_EE)}
            day={getStatAmount(data.plans_for_the_day, OossaSystemTypeEnum.ASKUE_EE)}
            fact={getStatAmount(data.facts_for_the_day, OossaSystemTypeEnum.ASKUE_EE)}
          />
        </Col>
        <Col span={6}>
          <Bars
            title="СМЗиС"
            month={getStatAmount(data.plans_for_the_month, OossaSystemTypeEnum.SMZIS)}
            day={getStatAmount(data.plans_for_the_day, OossaSystemTypeEnum.SMZIS)}
            fact={getStatAmount(data.facts_for_the_day, OossaSystemTypeEnum.SMZIS)}
          />
        </Col>
      </Row>
    </>
  );
}
