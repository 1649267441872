import { useLocation } from "react-router-dom";

import { AppRoutes } from "core/shared/constants.routes";
import { useCanChangeTask } from "core/shared/tasks/useCanChangeTask";

export function useDisabledByLocation(consumer?: "SignatureButtons") {
  const location = useLocation();
  const disabledByLocation =
    location.pathname === AppRoutes.tasks ||
    location.pathname === AppRoutes.slpi ||
    location.pathname === AppRoutes.oossa ||
    location.pathname === AppRoutes.equipments ||
    location.pathname === AppRoutes.base ||
    (location.pathname === AppRoutes.maintenance && consumer !== "SignatureButtons");

  // Дополнительное условие
  const canChange = useCanChangeTask();

  return disabledByLocation && !canChange;
}
