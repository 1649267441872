import { ReactElement } from "react";
import { Form, InputNumber } from "antd";

import { useWorkTypes } from "core/shared/main/useWorkTypes";
import { TimeInput } from "core/shared/components/TimeInput";

export const MOTO_WORD = "Хозяйки";

interface Props {
  required?: true;
  name: "effort_duration" | "effort";
}

function EffortField({ required, name }: Props): ReactElement {
  const workTypeId = Form.useWatch("workTypeId");
  const { data: workTypes } = useWorkTypes();

  let moto = false;
  if (workTypes) {
    const workType = workTypes.find((item) => item.id === workTypeId);
    if (workType?.name.includes(MOTO_WORD)) {
      moto = true;
    }
  }

  return (
    <Form.Item
      label="ПЛАНОВЫЕ ТРУДОЗАТРАТЫ"
      name={name}
      colon={false}
      required={required}
      rules={[
        {
          validator: (_, value: string) => {
            if (!value || value.includes("_")) {
              return Promise.reject();
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      {name === "effort" ? (
        <InputNumber size="large" addonAfter={moto ? "мтч." : "ч.ч."} min={0} />
      ) : (
        <TimeInput size="large" />
      )}
    </Form.Item>
  );
}

export default EffortField;
