import { useMemo } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { MaskedInput, IMask } from "antd-mask-input";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { MaskType } from "antd-mask-input/build/main/lib/MaskedInput";

interface TimeInputProps {
  value?: string;
  onChange?: (arg: any) => void;
  disabled?: boolean;
  size?: SizeType;
}

export function TimeInput({ value, onChange, disabled, size, ...rest }: TimeInputProps) {
  const shortValue = value?.slice(0, 5);

  const handleInputChange = (event: any) => {
    if (typeof onChange === "function") {
      onChange(`${event.maskedValue}:00`);
    }
  };
  const mask = useMemo<MaskType>(
    () => [
      {
        mask: "HH:mm",
        lazy: false,
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
          },
          mm: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
          },
        },
      },
    ],
    []
  );
  if (!onChange) {
    return <div />;
  }
  return (
    <MaskedInput
      mask={mask}
      size={size}
      disabled={disabled}
      value={shortValue}
      onChange={handleInputChange}
      suffix={<ClockCircleOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />}
      {...rest}
    />
  );
}
