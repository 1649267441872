import { ReactElement } from "react";

import useUiPermissions from "core/shared/permissions/hooks/useUiPermissions";
import { UiPermissionKey } from "core/shared/permissions/interfaces";
import { useNetworkState } from "core/shared/main/api/useNetworkState";

interface CanIVisitMenuItemProps {
  permissionKey: UiPermissionKey;
  name?: string;
  children?: ReactElement;
}

function CanIVisitMenuItem({ permissionKey, name, children }: CanIVisitMenuItemProps): ReactElement {
  const { isOnline } = useNetworkState();
  const permissions = useUiPermissions(permissionKey) as Record<string, boolean>;

  const isItemVisible =
    children && ((isOnline && permissions.CAN_SEE) || (!isOnline && permissions.CAN_SEE_IN_OFFLINE_MODE));

  if (!isItemVisible) {
    return <div data-permission-key={permissionKey} />;
  }

  return children;
}

export { CanIVisitMenuItem };
