import { Row, Col, Divider, Space, Modal } from "antd";
import { ReactElement, useState, useCallback } from "react";
import Collapse from "@mui/material/Collapse";
import clsx from "clsx";

import Text from "core/lib/Text/Text";
import { ITask } from "core/app/api/interfaces";
import { isOossaTask, isPspaTask, isSlpiTask } from "core/shared/task";
import {
  Number,
  RequestDescription,
  RequestCreationDatetime,
  State,
  RequestStatus,
  Location,
  Horizon,
  Address,
  System,
  EquipmentType,
  Initiator,
  Effort,
  AccumulatorElapsedTime,
  Customer,
  SuspendedUntil,
  WorkTimeUsageHours,
  OossaObject,
  OossaSystem,
} from "core/widgets/TaskModals/shared/Fields";
import { IcCollapse } from "core/widgets/IcCollapse";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке АВР. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;
  const [showFull, setShowFull] = useState(false);
  const [openTaskInfo, setOpenTaskInfo] = useState(false);

  const handleCloseTaskInfo = useCallback(() => {
    setOpenTaskInfo(false);
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Number task={task} />
          <RequestDescription task={task} />
        </Col>
      </Row>
      <Collapse in={showFull}>
        <Row gutter={16} className={clsx("mb-1", "mt-2")}>
          <Col span={12}>
            <RequestCreationDatetime task={task} />
            <State task={task} />
            <RequestStatus task={task} />
          </Col>
          <Col span={12}>
            {isPspaTask(task) && (
              <>
                <Location task={task} />
                <Horizon task={task} />
                {!task.is_mounted && <Address task={task} />}
                <System task={task} />
                <EquipmentType task={task} />
              </>
            )}
            {isOossaTask(task) && (
              <>
                <OossaObject task={task} />
                <OossaSystem task={task} />
              </>
            )}
            {isSlpiTask(task) && <Customer task={task} />}
            <Initiator task={task} />
            <Effort task={task} />
            <AccumulatorElapsedTime task={task} />
            <SuspendedUntil task={task} />
            <WorkTimeUsageHours task={task} />
          </Col>
        </Row>
      </Collapse>
      <Divider>
        <div
          className="link"
          onClick={() => {
            setShowFull(!showFull);
          }}
        >
          <Space>
            <Text className="blue" size="label3">
              {showFull ? "СВЕРНУТЬ ДОПОЛНИТЕЛЬНУЮ ИНФОРМАЦИЮ" : "РАЗВЕРНУТЬ ДОПОЛНИТЕЛЬНУЮ ИНФОРМАЦИЮ"}
            </Text>
            <IcCollapse open={!showFull} reverse />
          </Space>
        </div>
        <Modal
          title={`Описание задачи ${task.number}`}
          open={openTaskInfo}
          onOk={handleCloseTaskInfo}
          onCancel={handleCloseTaskInfo}
          cancelButtonProps={{ style: { display: "none" } }}
          okText="Закрыть"
        >
          <Text style={{ fontSize: "14px", lineHeight: "15px" }}>{task.request_description}</Text>
        </Modal>
      </Divider>
    </>
  );
}

export default GeneralInfo;
