import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";

export interface INetworkState {
  isOnline: boolean;
  detectionMode: "manual" | "auto";
}

const initialState: INetworkState = {
  isOnline: window.navigator.onLine,
  detectionMode: "manual",
};

export const NetworkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
      state.detectionMode = "manual";
    },
    setDetectionMode: (state, action: PayloadAction<INetworkState["detectionMode"]>) => {
      state.detectionMode = action.payload;
    },
  },
});

export const { setIsOnline, setDetectionMode: setNetworkDetectionMode } = NetworkSlice.actions;

export const networkSelector = createSelector(
  (state: RootState) => state.network,
  (state) => state
);
