import { Button } from "antd";

import { IEtoTask, StateNamesEnum } from "core/app/api/interfaces";
import { useStateButtonsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useDisabled";

import { useStateButtonsHandlers } from "../Header/StateButtons/hooks/useStateButtonsHandlers";

type TFooterProps = {
  task: IEtoTask;
};

function Footer({ task }: TFooterProps) {
  const { handleSave, updateTaskResult, updateStepResult } = useStateButtonsHandlers(task);
  const { isLoading: isTaskLoading } = updateTaskResult;
  const { isLoading: isStepLoading } = updateStepResult;
  const isLoading = isStepLoading || isTaskLoading;
  const disabledByShiftOrLocation = useStateButtonsDisabled();
  const disabledByStatus = task.state.name !== StateNamesEnum.InWork;
  const disabled = disabledByShiftOrLocation || disabledByStatus;

  return (
    <Button
      disabled={disabled}
      loading={isLoading}
      className="large w-100"
      type="primary"
      htmlType="submit"
      onClick={() => {
        handleSave();
      }}
    >
      Сохранить
    </Button>
  );
}

export default Footer;
