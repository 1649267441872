import { createSlice } from "@reduxjs/toolkit";

import { IEmployee, TaskTypesIdsEnum, EmployeeScheduleDayTypeEnum } from "core/app/api/interfaces";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";

export enum PrintTaskReportModalStates {
  Closed,
  CurrentUnit,
  AnyUnit,
}

interface ICurrentGroup {
  id?: number;
  name?: string;
  date_from: string;
  initial_shift_number: number;
  working_rhythm_id: number;
  locations: { location_id: number }[];
  employees: {
    employee_id: number;
    employee: IEmployee;
  }[];
}

interface ITaskFilterModalOptions {
  open: boolean;
}

export interface ICurrentEmployee {
  id: number;
  groupId: number;
  monthScheduleId: number;
  fullName?: string;
  position?: string;
  personnelNumber?: string;
  scheduleDayType: EmployeeScheduleDayTypeEnum;
}

interface ITempState {
  findOrCreateStationary: {
    equipmentId: number | null;
    monthPlanId: number | null;
    completedDate: string | null;
  } | null;
  createTaskModalType: TaskTypesIdsEnum | null;
  addGroupScheduleModalOpen: boolean;
  groupMembersModalOpen: boolean;
  currentGroup: ICurrentGroup | null;
  tasksFilterModalOptions: ITaskFilterModalOptions;
  currentEmployee: ICurrentEmployee | null;
  printTaskReportModalState: PrintTaskReportModalStates;
  employeeSearchString: string;
}

const initialState: ITempState = {
  findOrCreateStationary: null,
  createTaskModalType: null,
  addGroupScheduleModalOpen: false,
  groupMembersModalOpen: false,
  currentGroup: null,
  tasksFilterModalOptions: {
    open: false,
  },
  currentEmployee: null,
  printTaskReportModalState: PrintTaskReportModalStates.Closed,
  employeeSearchString: "",
};

export const TempSlice = createSlice({
  name: "temp",
  initialState,
  reducers: {
    setCreateTaskModalType(state, action) {
      state.createTaskModalType = action.payload;
    },
    setAddGroupScheduleModalOpen(state, action) {
      state.addGroupScheduleModalOpen = action.payload;
    },
    setGroupMembersModalOpen(state, action) {
      state.groupMembersModalOpen = action.payload;
    },
    createCurrentGroup(state) {
      state.currentGroup = {
        date_from: dayjs().format(apiDateFormat),
        initial_shift_number: 1,
        working_rhythm_id: 1,
        locations: [],
        employees: [],
      };
    },
    setCurrentGroup(state, action: { payload: ICurrentGroup | null }) {
      state.currentGroup = action.payload;
    },
    addGroupEmployee(state, action) {
      state.currentGroup?.employees?.push(action.payload);
    },
    removeGroupEmployee(state, action: { payload: number }) {
      if (state.currentGroup) {
        state.currentGroup.employees = state.currentGroup.employees?.filter(
          (employee) => employee.employee_id !== action.payload
        );
      }
    },
    setFindOrCreateStationary(state, action) {
      state.findOrCreateStationary = action.payload;
    },
    setTasksFilterModalOptions(state, action: { payload: ITaskFilterModalOptions }) {
      state.tasksFilterModalOptions = { ...action.payload };
    },
    setCurrentEmployee(state, action: { payload: ICurrentEmployee | null }) {
      state.currentEmployee = action.payload;
    },
    setPrintTaskReportModalState(state, actioin: { payload: PrintTaskReportModalStates }) {
      state.printTaskReportModalState = actioin.payload;
    },
    setEmployeeSearchString(state, action: { payload: string }) {
      state.employeeSearchString = action.payload;
    },
  },
});

export const {
  setCreateTaskModalType,
  setAddGroupScheduleModalOpen,
  setGroupMembersModalOpen,
  addGroupEmployee,
  removeGroupEmployee,
  setCurrentGroup,
  createCurrentGroup,
  setFindOrCreateStationary,
  setTasksFilterModalOptions,
  setCurrentEmployee,
  setPrintTaskReportModalState,
  setEmployeeSearchString,
} = TempSlice.actions;
