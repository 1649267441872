import type { RcFile } from "antd/es/upload/interface";

export function checkValidFormatFile(file: RcFile | File) {
  const { name, type } = file;
  const VALID_FORMAT_FILE = ["doc", "docx", "xls", "xlsx", "pdf", "msg"];
  const format = name.split(".").pop();
  if (!format) return false;
  if (type.includes("image") || type.includes("video")) return true;
  return VALID_FORMAT_FILE.includes(format);
}
