import { ReactElement } from "react";
import { Select, Form } from "antd";

import { useCustomerDepartments } from "core/shared/main/useCustomerDepartments";
import { useUnitId } from "core/shared/unit/useUnitId";

import { useIsFieldRequired } from "../../useIsFieldRequired";
import { FIELD_NAMES } from "../../constants";

export function CustomerDepartment(): ReactElement {
  const [unitId] = useUnitId();
  const required = useIsFieldRequired(FIELD_NAMES.CUSTOMER_DEPARTMENT);
  const form = Form.useFormInstance();

  const { data, isFetching } = useCustomerDepartments({ unit_id: unitId });
  const options = (data || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSelectChange = () => {
    form.setFieldsValue({
      nodeId: undefined,
      systemId: null,
      objectId: undefined,
      equipmentId: undefined,
    });
  };

  return (
    <Form.Item
      label="БАЛАНСОДЕРЖАТЕЛЬ"
      name={FIELD_NAMES.CUSTOMER_DEPARTMENT}
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}
