import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { apiSlice } from "core/app/api/api";

import { MainSlice } from "./main-slice";
import { TaskSlice } from "./task-slice";
import { DateSlice } from "./date-slice";
import { EmployeeSlice } from "./employee-slice";
import { TeamSlice } from "./team-slice";
import { CurrentTeamSlice } from "./current-team-slice";
import { MaintenanceSlice } from "./maintenance-slice";
import { MaintenanceAttachedSlice } from "./maintenance-attached-slice";
import { TasksSlice } from "./tasks-slice";
import { TasksForShiftSlice } from "./tasks-for-shift-slice";
import { UserSlice } from "./user-slice";
import { EmployeesScheduleSlice } from "./employees-schedule-slice";
import { DashboardSlice } from "./dashboard-slice";
import { TempSlice } from "./temp-slice";
import { FindOrCreateSlice } from "./find-or-create-slice";
import { VersionSlice } from "./version-slice";
import { TokenSlice } from "./token-slice";
import { ShiftsJournalSlice } from "./shifts-journal-slice";
import { OossaSlice } from "./oossa-slice";
import { TaskSignatureSlice } from "./task-signature-slice";
import { NetworkSlice } from "./network-slice";
import { TeamCopyModalSlice } from "./team-copy-modal-slice";
import { PssSlice } from "./pss-slice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["api", "mockApi", "temp", "task", "find-or-create", "shifts-journal"],
};

const rootReducer = combineReducers({
  main: MainSlice.reducer,
  task: TaskSlice.reducer,
  date: DateSlice.reducer,
  employee: EmployeeSlice.reducer,
  team: TeamSlice.reducer,
  currentTeam: CurrentTeamSlice.reducer,
  maintenance: MaintenanceSlice.reducer,
  maintenanceAttached: MaintenanceAttachedSlice.reducer,
  tasks: TasksSlice.reducer,
  tasksForShift: TasksForShiftSlice.reducer,
  user: UserSlice.reducer,
  employeesSchedule: EmployeesScheduleSlice.reducer,
  dashboard: DashboardSlice.reducer,
  temp: TempSlice.reducer,
  findOrCreate: FindOrCreateSlice.reducer,
  version: VersionSlice.reducer,
  token: TokenSlice.reducer,
  shiftsJournal: ShiftsJournalSlice.reducer,
  oossa: OossaSlice.reducer,
  pss: PssSlice.reducer,
  taskSignature: TaskSignatureSlice.reducer,
  network: NetworkSlice.reducer,
  teamCopyModal: TeamCopyModalSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
