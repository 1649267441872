import { Row, Col, Button, Space } from "antd";

import { IEtoTask, EtoTaskStepNameEnum } from "core/app/api/interfaces";
import { useDisabledByLocation } from "core/shared/task/useDisabledByLocation";
import { useAssistanceCarMaintenanceContext } from "core/widgets/AssistanceCarTaskModal/hooks/useAssistanceCarMaintenanceContext";
import { FormPages } from "core/widgets/AssistanceCarTaskModal/AssistanceCarMaintenanceContext";
import { useCurrentStep } from "core/widgets/AssistanceCarTaskModal/hooks/useCurrentStep";

import { EtoTaskSignatureSide } from "../../interfaces";

import { SignatureCompleted } from "./SignatureCompleted";

interface SignatureButtonsProps {
  task: IEtoTask;
}

function SignatureButtons({ task }: SignatureButtonsProps) {
  const { setPage, setSignatureSide } = useAssistanceCarMaintenanceContext();
  const step = useCurrentStep();
  const disabledByLocation = useDisabledByLocation("SignatureButtons");

  const showInitialSignatures = [EtoTaskStepNameEnum.PRE_SHIFT_CHECK, EtoTaskStepNameEnum.PERFORMING_ETO].includes(
    step?.step_name as EtoTaskStepNameEnum
  );
  const showFinishSignatures = step?.step_name === EtoTaskStepNameEnum.POST_SHIFT_CHECK;
  const isDriverSignatureExists = Boolean(step?.driver_signature_id);
  const isMechanistSignatureExists = Boolean(step?.mechanic_signature_id);

  return (
    <Space className="w-100" direction="vertical" size={16}>
      <Row gutter={36}>
        {showInitialSignatures && isMechanistSignatureExists && (
          <Col span={12}>
            <SignatureCompleted taskId={task.id} side={EtoTaskSignatureSide.SENIOR_ELECTRICIAN} />
          </Col>
        )}
        {showInitialSignatures && isDriverSignatureExists && (
          <Col span={12}>
            <SignatureCompleted taskId={task.id} side={EtoTaskSignatureSide.MACHINIST} />
          </Col>
        )}
        {showFinishSignatures && isDriverSignatureExists && (
          <Col span={24}>
            <SignatureCompleted taskId={task.id} side={EtoTaskSignatureSide.MACHINIST} />
          </Col>
        )}
      </Row>
      <Row gutter={36}>
        {showInitialSignatures && (
          <Col span={12}>
            <Button
              disabled={disabledByLocation}
              onClick={() => {
                setPage(FormPages.SIGNATURE);
                setSignatureSide(EtoTaskSignatureSide.SENIOR_ELECTRICIAN);
              }}
              style={{ width: "100%" }}
              type="primary"
            >
              ВЫПУСКАЮЩИЙ ЭЛЕКТРОМЕХАНИК
            </Button>
          </Col>
        )}
        {showInitialSignatures && (
          <Col span={12}>
            <Button
              disabled={disabledByLocation}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setPage(FormPages.SIGNATURE);
                setSignatureSide(EtoTaskSignatureSide.MACHINIST);
              }}
            >
              МАШИНИСТ
            </Button>
          </Col>
        )}
        {showFinishSignatures && (
          <Col span={24}>
            <Button
              disabled={disabledByLocation}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setPage(FormPages.SIGNATURE);
                setSignatureSide(EtoTaskSignatureSide.MACHINIST);
              }}
            >
              МАШИНИСТ
            </Button>
          </Col>
        )}
      </Row>
    </Space>
  );
}

export { SignatureButtons };
