import { skipToken } from "@reduxjs/toolkit/dist/query";

import { ITask } from "core/app/api/interfaces";
import { useGetOossaEquipmentQuery, useGetOossaTechNodeQuery } from "core/app/api";

interface TaskCaptionProps {
  task: ITask;
}

/** Наименование (элемент) карточки задания (ТО подвесных, ТО стационарных, АВР, доп. работ)
 * с всплывающей подсказкой для ООССА. */
export function OossaTaskCaption({ task }: TaskCaptionProps) {
  const { data: oossaEquipment } = useGetOossaEquipmentQuery(task?.oossa_equipment_id ?? skipToken);
  const { data: oossaTechNode } = useGetOossaTechNodeQuery(task?.oossa_tech_node_id ?? skipToken);
  const caption = oossaEquipment?.name || oossaTechNode?.name || "Не выбрано";

  return <span data-task-id={task.id}>{caption}</span>;
}
