import { StateNamesEnum, TaskSnapMomentEnum } from "core/app/api/interfaces";
import dayjs from "core/shared/tools/dayjs";

import { useTask } from "../useTask";
import { useDisabledByLocation } from "../useDisabledByLocation";
import { useDisabledByShiftStatus } from "../useDisabledByShift";
import { useTaskId } from "../useTaskId";

interface Props {
  skipStatusCheck?: boolean;
}

/**
 * Блокировка задачи
 * @returns
 */
export function useDisabled(props?: Props) {
  const { data: task } = useTask();
  const [, , moment] = useTaskId();
  const shiftDate = dayjs(task?.planned_date);
  const disabledByStatus = !task?.state || task.state.name !== StateNamesEnum.InWork;
  const disabledByShiftStatus = useDisabledByShiftStatus(shiftDate);
  const disabledByLocation = useDisabledByLocation();
  let disabled = disabledByLocation || disabledByShiftStatus;

  if (!props?.skipStatusCheck) {
    disabled = disabled || disabledByStatus || moment === TaskSnapMomentEnum.TEAM_DISBAND;
  }
  return disabled;
}
