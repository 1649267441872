import qs from "qs";
import { Dayjs } from "dayjs";

import { apiDateFormat } from "core/shared/tools/dayjs";
import { useGetShiftPlanQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import { IShiftPlan } from "core/app/api/interfaces";
import { useUnitDepartmentId } from "core/shared/main/useUnitDepartmentId";
import { useShift } from "core/shared/main/store/useShift";
import { useDate } from "core/shared/main/store/useDate";

const useShiftPlan = (
  date?: Dayjs,
  unitIdCustom?: number | null,
  shiftCustom?: number | null
): IShiftPlan | undefined => {
  const [unitId] = useUnitId();
  const [storeDate] = useDate();
  const [shift] = useShift();
  const [unitDepartmentId] = useUnitDepartmentId();
  const queryDate = date || storeDate;

  const queryString = qs.stringify({
    unit_id: unitIdCustom ?? unitId,
    date: queryDate.format(apiDateFormat),
    shift_num: shiftCustom ?? (shift?.number || 1),
    unit_department_id: unitDepartmentId ?? undefined,
  });

  const { data, isError } = useGetShiftPlanQuery(queryString);

  return isError ? undefined : data?.[0];
};

export { useShiftPlan };
