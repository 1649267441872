import { ReactElement } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

import Text from "core/lib/Text/Text";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IProps {
  title?: string;
  aspectRatio?: number;
  month?: number;
  day?: number;
  fact?: number;
}

export function Bars({ title, aspectRatio, month = 0, day = 0, fact = 0 }: IProps): ReactElement {
  const options = {
    aspectRatio: aspectRatio ?? 1.5,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = ["Месяц", "Тек. дата", "Факт", "Отклонение", "Остаток"];

  const diff = fact - day;
  const left = month - fact;

  const barData = {
    labels,
    datasets: [
      {
        label: title,
        data: [month, day, fact, diff, left],
        backgroundColor: ["#B1DE9C", "#FFCE51", "#DEA4FA", "#FF6442", "#BAD3EA"],
        labels: ["Месяц", "Тек. дата", "Факт", "Отклонение", "Остаток"],
      },
    ],
  };

  return (
    <>
      <div className="mb-2">
        <Text className="uppercase" boldness="semibold">
          {title}
        </Text>
      </div>
      <Bar height="200px" options={options} data={barData} />
    </>
  );
}
