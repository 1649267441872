export const AppRoutes = {
  base: "/",
  teams: "/teams",
  maintenance: "/maintenance",
  tasksForTeams: "/tasks-for-teams",
  taskForShift: "/tasks-for-shift",
  employeesSchedule: "/employees-schedule",
  tasks: "/tasks",
  equipments: "/equipments",
  slpi: "/slpi",
  help: "/help",
  rating: "/rating",
  dailyReport: "/daily-report",
  oossa: "/oossa",
  lku: "/lku",
  taskSignatureJournal: "/task-signature-journal",
  pss: "/pss",
};
