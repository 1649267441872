import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "core/app/store";
import { setSignatureStatus, SignatureStatus } from "core/app/store/task-slice";

const useSignatureStatus = (): [SignatureStatus | null, (value: SignatureStatus) => void] => {
  const { signatureStatus } = useAppSelector((state) => state.task);
  const dispatch = useAppDispatch();

  const set = useCallback(
    (newValue: SignatureStatus) => {
      dispatch(setSignatureStatus(newValue));
    },
    [dispatch]
  );

  return [signatureStatus, set];
};

export { useSignatureStatus };
