import { useUnit } from "core/shared/unit/useUnit";

/**
 * Слайсы(разделы) апишки на основании услуги, для получения специфичных для услуги данных.
 * Например, задачи по оосса получаем из точки oossa-tasks, для слпи из slpi-tasks.
 */
export enum ApiSlice {
  oossa = "oossa",
  pspa = "pspa",
  slpi = "slpi",
}

export function useApiSlice() {
  const unit = useUnit();
  let slice: null | ApiSlice = null;
  if (unit?.isOOSSA()) {
    slice = ApiSlice.oossa;
  }
  if (unit?.isPSPA()) {
    slice = ApiSlice.pspa;
  }
  if (unit?.isSLPI()) {
    slice = ApiSlice.slpi;
  }
  return slice;
}
