import { Button, Col, Form, Row, message } from "antd";
import { useEffect, useState } from "react";

import Text from "core/lib/Text/Text";
import { useAppDispatch, useAppSelector } from "core/app/store";
import { setCreateTaskModalType } from "core/app/store/temp-slice";
import { IAttachment, TSendOtherOossaTaskBody, StateNamesEnum, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetTaskStatesQuery, useCreateOossaTaskMutation } from "core/app/api";
import dayjs, { apiDateFormat, apiDateTimeFormat } from "core/shared/tools/dayjs";
import { IDictionaryItem } from "core/shared/interfaces";
import EffortField from "core/widgets/CreateTaskModal/Fields/EffortField";
import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";
import AttachmentsField from "core/widgets/CreateTaskModal/Fields/AttachmentsField";
import ShiftSelector from "core/widgets/CreateTaskModal/Fields/ShiftSelector";
import { taskTypeTitleMap } from "core/widgets/CreateTaskModal";
import DescriptionField from "core/widgets/CreateTaskModal/Fields/DescriptionField";

import { WorkType } from "./Fields/WorkType";
import { CustomerDepartment } from "./Fields/CustomerDepartment";
import { Object } from "./Fields/Object";
import { System } from "./Fields/System";
import { Node } from "./Fields/Node";
import { Equipment } from "./Fields/Equipment";

export function Oossa() {
  const dispatch = useAppDispatch();
  const [unitId] = useUnitId();
  const { createTaskModalType } = useAppSelector((state) => state.temp);
  const [sendTask, sendTaskResult] = useCreateOossaTaskMutation();
  const { data: states } = useGetTaskStatesQuery("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [form] = Form.useForm();
  const initialValues = {
    equipmentId: undefined,
    attachments: [],
    effort_duration: "00:00:00",
    teamId: null,
    shiftNumber: 1 as 1 | 2 | 3,
    plannedDate: "",
    customerDepartmentId: null,
    objectId: undefined,
    systemComplexId: null,
    workTypeId: null,
    systemId: null,
    nodeId: undefined,
    description: "",
  };

  const handleCancel = () => {
    dispatch(setCreateTaskModalType(null));
  };

  const handleSubmit = async (values: typeof initialValues) => {
    if (!unitId || !createTaskModalType || !states) {
      return;
    }
    const state = states.results.find((item: IDictionaryItem) => item.name === StateNamesEnum.New);

    setShowErrorMessage(false);
    const body: TSendOtherOossaTaskBody = {
      type: TaskTypesIdsEnum.OTHER,
      state_id: state?.id,
      unit_id: unitId,
      work_type_id: values.workTypeId,
      customer_department_id: values.customerDepartmentId,
      effort_duration: values.effort_duration,
      oossa_equipment_id: values.equipmentId,
      oossa_tech_node_id: values.nodeId,
      system_id: values.systemId,
      request_creation_datetime: dayjs().format(apiDateTimeFormat),
      oossa_object_id: values.objectId,
      request_description: values.description,
    };
    if (values.teamId) {
      body.team_id = values.teamId;
    }
    if (values.shiftNumber) {
      body.shift_number = values.shiftNumber;
    }
    if (values.plannedDate) {
      body.planned_date = dayjs(values.plannedDate).format(apiDateFormat);
    }
    if (values.attachments) {
      body.attachments = {
        files_by_task: values.attachments as IAttachment[],
        work_place: [],
        before_or_in_progress: [],
        after_completion: [],
      };
    }

    try {
      await sendTask(body).unwrap();
      message.success("Заявка создана успешно.");
      handleCancel();
    } catch (e) {
      message.error("При создании заявки произошла ошибка.");
    }
  };

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };

  useEffect(() => {
    form.resetFields();
    setShowErrorMessage(false);
  }, [createTaskModalType, form]);

  return (
    <Form
      className="create-recovery-task-modal__form"
      form={form}
      layout="vertical"
      name="custom-task"
      initialValues={initialValues}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={handleValidationFailed}
    >
      <Row gutter={24}>
        <Col className="mb-3" span={24}>
          {createTaskModalType && (
            <Text boldness="black" size="h4" className="uppercase">
              Создание {taskTypeTitleMap[createTaskModalType]}
            </Text>
          )}
        </Col>
        {showErrorMessage && (
          <Col className="mb-3" span={24}>
            <ErrorMessage />
          </Col>
        )}
        <Col span={12}>
          <WorkType />
        </Col>
        <Col span={12}>
          <CustomerDepartment />
        </Col>
        <Col span={12}>
          <Object />
        </Col>
        <Col span={12}>
          <System />
        </Col>
        <Col span={12}>
          <Node />
        </Col>
        <Col span={12}>
          <Equipment />
        </Col>
        <Col span={24}>
          <DescriptionField />
        </Col>
        <Col span={12}>
          <EffortField name="effort_duration" required />
        </Col>
        <Col span={24}>
          <AttachmentsField />
        </Col>
        <Col className="mb-1 mt-1" span={24}>
          <ShiftSelector />
        </Col>
      </Row>
      <Row gutter={32} justify="center" className="mt-4">
        <Col span={12}>
          <Button className="w-100 modal-button" size="large" onClick={handleCancel}>
            ОТМЕНИТЬ
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className="w-100 modal-button"
            size="large"
            type="primary"
            htmlType="submit"
            loading={sendTaskResult.isLoading}
          >
            СОЗДАТЬ
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
