import { ReactElement, useContext } from "react";

import { useGetDashboardShiftStatisticTasksByTeamsOossaQuery } from "core/app/api";
import { TeamSelector } from "core/shared/dashboard/ui/TeamSelector";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

function TeamSelectorOossa(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isSuccess, isFetching } = useGetDashboardShiftStatisticTasksByTeamsOossaQuery(queryString, {
    skip: !queryString,
  });

  if (!isFetching && isSuccess && data) {
    return <TeamSelector teams={data} />;
  }
  return <div />;
}

export { TeamSelectorOossa };
