import { ReactElement, useContext } from "react";
import { Row, Col, Radio, Switch, Space } from "antd";
import { sortBy } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import Text from "core/lib/Text/Text";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { IDashboardShiftStatisticTasksByTeams } from "core/app/api/interfaces";

interface TeamSelectorProps {
  teams: IDashboardShiftStatisticTasksByTeams<any>[];
}

function TeamSelector({ teams }: TeamSelectorProps): ReactElement {
  const { teamId, setTeamId, showOutOfPlan, setShowOutOfPlan } = useContext(ShiftContext);

  const handleShowOutOfPlanChange = () => {
    setShowOutOfPlan(!showOutOfPlan);
  };

  if (teams) {
    return (
      <Row gutter={64}>
        <Col>
          <Space direction="vertical" size="small">
            <Radio.Group
              defaultValue=""
              value={teamId}
              onChange={(e) => setTeamId(`${e.target.value}`)}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio.Button value="">Заявки</Radio.Button>
              {sortBy(teams, "team_number").map((item) => (
                <Radio.Button key={item.team_id} value={`${item.team_id}`} style={{ alignContent: "center" }}>
                  {`№${item.team_number} ${item.team_name} `}
                  {item.disbanded && <ExclamationCircleOutlined />}
                </Radio.Button>
              ))}
            </Radio.Group>
            {!!teams.length && (
              <Space>
                <ExclamationCircleOutlined size={10} style={{ width: 12 }} />
                <Text size="label3">- Бригада расформирована</Text>
              </Space>
            )}
          </Space>
        </Col>
        {!teamId && (
          <Col>
            <Space>
              <Switch checked={showOutOfPlan} onChange={handleShowOutOfPlanChange} />
              <Text>Показать заявки вне плана</Text>
            </Space>
          </Col>
        )}
      </Row>
    );
  }
  return <div />;
}

export { TeamSelector };
