import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { hoursToString } from "core/shared/tools/hoursToString";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function WorkTimeUsageHours({ task }: FieldProps): ReactElement {
  const workTimeUsageHoursFormatted = hoursToString(task.work_time_usage_hours || 0);
  return <Field label="Остаток" value={workTimeUsageHoursFormatted} />;
}

export { WorkTimeUsageHours };
