import { ReactElement } from "react";

import { ITask, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { getSystemsString } from "core/pages/SLPI/Yearly/SlpiObject/tools";
import { isOossaTask, isSlpiTask } from "core/shared/task";
import { OossaTaskCaption } from "core/widgets/Oossa/OossaMaintenanceTaskContent/OossaMaintenanceTaskGeneralInfo/OossaTaskCaption";

interface TaskCaptionProps {
  task: ITask;
}

/** Наименование (элемент) карточки задания (ТО подвесных, ТО стационарных, АВР, доп. работ). */
export function TaskCaption({ task }: TaskCaptionProps): ReactElement {
  if (isOossaTask(task)) return <OossaTaskCaption task={task} />;

  const EMPTY = "Не выбрано";
  let caption = EMPTY;

  if (task.type === TaskTypesIdsEnum.OTHER) {
    caption = task.work_type?.name || EMPTY;
  } else if (task.type === TaskTypesIdsEnum.ETO && task.transport) {
    caption = task.transport.name;
  } else if (task.is_mounted && task.transport) {
    caption = task.transport.name;
  } else if (!task.is_mounted && task.equipment) {
    caption = task.equipment.name;
  } else if (!task.is_mounted && isSlpiTask(task)) {
    if (task.system_complex?.systems) {
      caption = getSystemsString(task.system_complex.systems);
    }
  }

  return <span data-task-id={task.id}>{caption}</span>;
}
