import { Form, Input } from "antd";

import { useWorkType } from "core/shared/slpi/useWorkType";
import { SlpiWorkTypeGroupEnum } from "core/app/api/interfaces";

import "./styles.scss";

export function Description() {
  const workTypeId = Form.useWatch("workTypeId");
  const workType = useWorkType(workTypeId);

  return (
    <Form.Item
      label="ОПИСАНИЕ"
      name="description"
      rules={[
        {
          required:
            workType?.group === SlpiWorkTypeGroupEnum.WORK || workType?.group === SlpiWorkTypeGroupEnum.RESEARCH,
          message: "",
        },
      ]}
    >
      <Input.TextArea className="description-field" size="large" showCount maxLength={255} rows={6} />
    </Form.Item>
  );
}
