import { useLocation } from "react-router-dom";
import { Dayjs } from "dayjs";

import { useShiftPlan } from "core/shared/shift/useShiftPlan";
import { ShiftPlanStatusesEnum, TaskSnapMomentEnum } from "core/app/api/interfaces";
import { AppRoutes } from "core/shared/constants.routes";

import { useTaskId } from "../useTaskId";

const useDisabledByShiftStatus = (shiftDate?: Dayjs): boolean => {
  const location = useLocation();

  const shiftPlan = useShiftPlan(shiftDate);

  const [, , moment] = useTaskId();
  const checkMoment = moment === TaskSnapMomentEnum.TEAM_DISBAND;

  if (location.pathname === AppRoutes.maintenance) {
    return false;
  }

  return !shiftPlan || shiftPlan.status !== ShiftPlanStatusesEnum.APPROVED || checkMoment;
};

export { useDisabledByShiftStatus };
