import { Row, Col } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { isOossaTask, isSlpiTask } from "core/shared/task";
import {
  PlannedDate,
  Customer,
  Location,
  Unit,
  Address,
  AccumulatorElapsedTime,
} from "core/widgets/TaskModals/shared/Fields";

import OossaMaintenanceTaskGeneralInfo from "../../../../Oossa/OossaMaintenanceTaskContent/OossaMaintenanceTaskGeneralInfo";

import SLPIGeneralInfo from "./SLPI";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО стационарного оборудования ПСПА, СДГР, СЛПИ, ООССА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;

  if (isSlpiTask(task)) {
    return <SLPIGeneralInfo task={task} />;
  }

  if (isOossaTask(task)) {
    return <OossaMaintenanceTaskGeneralInfo task={task} />;
  }

  return (
    <Row className="mb-1">
      <Col>
        <PlannedDate task={task} />
        <Customer task={task} />
        <Unit task={task} />
        <Location task={task} />
        <Address task={task} />
        <AccumulatorElapsedTime task={task} />
      </Col>
    </Row>
  );
}

export default GeneralInfo;
