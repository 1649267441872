import { ReactElement } from "react";
import { Select, Form } from "antd";

import { useGetOossaWorkTypesQuery } from "core/app/api";

import { FIELD_NAMES } from "../../constants";

export function WorkType(): ReactElement {
  const { data, isFetching } = useGetOossaWorkTypesQuery("");
  const options = (data || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form.Item
      label="ВИД РАБОТ"
      name={FIELD_NAMES.WORK_TYPE}
      colon={false}
      required
      rules={[
        {
          required: true,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}
