import { Form, Select } from "antd";

import { IItsmCategory } from "core/app/api/interfaces";
import "core/widgets/CreateTaskModal/styles.scss";

import { useItsmCategories } from "../hooks";

function RequestCategoryField() {
  const { data, isFetching } = useItsmCategories();
  const form = Form.useFormInstance();
  const options = (data || []).map((item: IItsmCategory) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (value: string) => {
    form.setFieldValue("creatioEquipmentId", null);
    const requestCategory = data?.find((item) => item.id === value);
    if (requestCategory?.required_customer_approval) {
      form.setFieldValue("taskListOrshift", "taskList");
    }
  };

  return (
    <Form.Item
      label="КАТЕГОРИЯ ЗАПРОСА"
      name="requestCategoryId"
      colon={false}
      rules={[{ required: true, message: "" }]}
    >
      <Select
        onChange={handleChange}
        className="request-category-field"
        size="large"
        dropdownMatchSelectWidth={720}
        allowClear
        optionFilterProp="children"
        showSearch
        loading={isFetching}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}

export default RequestCategoryField;
