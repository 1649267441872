import { ReactElement } from "react";

import Text, { Size } from "core/lib/Text/Text";
import { IEtoTask } from "core/app/api/interfaces";

interface TaskTitleProps {
  task: IEtoTask;
  size?: Size;
}

function TaskTitle(props: TaskTitleProps): ReactElement {
  const { task, size } = props;

  return (
    <div className="mb-1" id={`${task.id}`}>
      <Text className="name uppercase" size={size ?? "h4"} boldness="black">
        <span data-task-id={task.id}>{task.transport.name}</span>
      </Text>
    </div>
  );
}

export default TaskTitle;
