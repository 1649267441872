import { useGetWorkTypesQuery } from "core/app/api";
import { useUnit } from "core/shared/unit/useUnit";

export function useWorkTypes() {
  const unit = useUnit();
  let qs = "work-types";
  if (unit?.isSLPI()) {
    qs = "slpi-work-types";
  }
  return useGetWorkTypesQuery(qs);
}
