import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IFilter } from "core/shared/interfaces";
import { MaintenanceTabs } from "core/shared/maintenance/types";

export interface IColumn {
  id: string;
  tag: string;
  name: string;
  enabled: boolean;
  canBeFiltered: boolean;
  canBeHidden: boolean;
  tooltip?: string;
  columnName: string;
  path: string;
  minWidth?: number;
}

export interface IMaintenance {
  showFact: boolean;
  showLeft: boolean;
  systemId: string;
  stampOpened: boolean;
  selectedTab: MaintenanceTabs;
  localUnitId: number | null;
  filters: IFilter[];
  columns: IColumn[];
}

const initialState: IMaintenance = {
  showFact: true,
  showLeft: true,
  systemId: "1",
  stampOpened: false,
  selectedTab: MaintenanceTabs.YEARLY,
  localUnitId: null,
  filters: [
    {
      label: "Единица оборудования",
      key: "equipment__name",
      value: "",
    },
    {
      label: "Участок",
      key: "equipment__location",
      value: "",
    },
    {
      label: "ФИО",
      key: "fio",
      value: "",
    },
    {
      label: "Статус обслуживания",
      key: "maintenance_result",
      value: "",
    },
  ],
  columns: [
    {
      id: "1",
      tag: "title",
      name: "Единицы оборудования",
      columnName: "ЕДИНИЦЫ ОБОРУД.",
      path: "equipment.name",
      enabled: true,
      canBeFiltered: true,
      canBeHidden: false,
    },
    {
      id: "3",
      tag: "location",
      name: "Участок",
      columnName: "УЧАСТОК",
      path: "transport.location.name",
      enabled: true,
      canBeFiltered: true,
      canBeHidden: true,
    },
    {
      id: "5",
      tag: "group",
      name: "Группа",
      columnName: "Группа",
      path: "none",
      enabled: false,
      canBeFiltered: false,
      canBeHidden: false,
    },
    {
      id: "6",
      tag: "comment",
      name: "Комментарий",
      columnName: "КОММЕНТАРИЙ",
      path: "last_closed_task.comment",
      enabled: true,
      canBeFiltered: false,
      canBeHidden: true,
      tooltip: "Общий комментарий в карточке/акте ТО",
      minWidth: 130,
    },
    {
      id: "7",
      tag: "fio",
      name: "ФИО",
      columnName: "ФИО",
      path: "last_closed_task.executor.full_name",
      enabled: false,
      canBeFiltered: true,
      canBeHidden: true,
      tooltip: "Сотрудник, который выполнил ТО",
      minWidth: 60,
    },
  ],
};

export const PssSlice = createSlice({
  name: "pss",
  initialState,
  reducers: {
    switchShowFact(state) {
      state.showFact = !state.showFact;
    },
    switchShowLeft(state) {
      state.showLeft = !state.showLeft;
    },
    setSystemId(state, action) {
      state.systemId = action.payload;
    },
    setStampOpened(state, action) {
      state.stampOpened = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    toggleColumn(state, action) {
      const index = state.columns.findIndex((column) => column.id === action.payload.id);
      const column = state.columns[index];
      state.columns[index] = {
        ...column,
        enabled: !column.enabled,
      };
    },
    setFilters(state, action: PayloadAction<IFilter[]>) {
      state.filters = action.payload;
    },
    setLocalUnitId(state, action: PayloadAction<IMaintenance["localUnitId"]>) {
      state.localUnitId = action.payload;
    },
    resetFilters(state) {
      state.filters = initialState.filters;
    },
    reset() {
      return initialState;
    },
  },
});
