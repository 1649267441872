import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import localizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";
import toObject from "dayjs/plugin/toObject";
import duration from "dayjs/plugin/duration";

dayjs.locale("ru");
dayjs.extend(localizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(toObject);
dayjs.extend(duration);

export function parseDuration(time: string) {
  let dur: duration.Duration | undefined;
  const [first, second] = time.split(" ");
  if (second) {
    const [hours, minutes, seconds] = second.split(":");
    dur = dayjs.duration({
      seconds: parseInt(seconds, 10),
      minutes: parseInt(minutes, 10),
      hours: parseInt(hours, 10),
      days: parseInt(first, 10),
    });
  } else {
    const [hours, minutes, seconds] = first.split(":");
    dur = dayjs.duration({
      seconds: parseInt(seconds, 10),
      minutes: parseInt(minutes, 10),
      hours: parseInt(hours, 10),
    });
  }
  return dur;
}

export function getDayJsFromDurationString(time: string | null | undefined) {
  let dayjsTime = dayjs("00:00", "HH:mm");
  if (!time) {
    return dayjsTime;
  }
  const dur = parseDuration(time);
  dayjsTime = dayjsTime.set("hours", dur.get("hours"));
  dayjsTime = dayjsTime.set("minutes", dur.get("minutes"));
  dayjsTime = dayjsTime.set("seconds", dur.get("seconds"));
  return dayjsTime;
}

/** Вернуть округленное до 2 знаков после запятой количество часов в строке, заданной в формате времени ("ДД ЧЧ:ММ:СС" или "ЧЧ:ММ:СС"). */
export function getHoursFromTime(time: string | undefined | null, round: boolean): number {
  let elapsedTime = 0;
  if (!time) {
    return elapsedTime;
  }
  elapsedTime = parseDuration(time).asHours();
  if (round) {
    return Math.round(elapsedTime * 100) / 100;
  }
  return elapsedTime;
}

export function getTimeFromHours(hours?: number | string | null): string {
  let h = 0;
  if (typeof hours === "string") {
    h = parseFloat(hours);
  } else if (typeof hours === "number") {
    h = hours;
  }
  return dayjs.duration(h, "hours").format("DD HH:mm:ss");
}

export function getDurationString(date?: Dayjs | null) {
  if (!date) {
    return "00:00:00";
  }
  return dayjs(date).format("HH:mm:ss");
}

export function getDayJsFromHours(hours?: number | string | null) {
  return getDayJsFromDurationString(getTimeFromHours(hours));
}

export function getHoursFromDayJs(date?: Dayjs | null) {
  return getHoursFromTime(getDurationString(date), false);
}

export const apiDateFormat = "YYYY-MM-DD";
export const apiDateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const displayDateFormat = "DD.MM.YYYY";
export const displayTimeFormat = "H:mm:ss";
export const hmTimeFormat = "HH:mm";
export const hmDisplayFormat = "Hч. mmм.";

export function formatHours(effort: number | null) {
  return getDayJsFromHours(effort).format(hmDisplayFormat);
}

export function formatDuration(durationString: string | null | undefined) {
  return getDayJsFromDurationString(durationString).format(hmDisplayFormat);
}

export default dayjs;
