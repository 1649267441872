import React, { useEffect } from "react";
import { PictureOutlined } from "@ant-design/icons";
import { message, Upload, Button } from "antd";
import type { RcFile } from "antd/es/upload/interface";

import { useUploadAttachmentMutation } from "core/app/api";
import { TaskAttachmentCategoryEnum, EtoTaskAttachmentCategoryEnum } from "core/app/api/interfaces";
import { fileProcessing } from "core/shared/tools/fileProcessing";

interface UploadMediaProps {
  taskId: number;
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum;
  hide: () => void;
}

function UploadMedia({ taskId, category, hide }: UploadMediaProps) {
  const [uploadAttachmentRequest, uploadAttachmentResult] = useUploadAttachmentMutation();

  useEffect(() => {
    if (uploadAttachmentResult.isSuccess) {
      message.success("Файл загружен!");
    }
    if (uploadAttachmentResult.isError) {
      message.error("При загрузке файла, произошла ошибка!");
    }
  }, [uploadAttachmentResult.isSuccess, uploadAttachmentResult.isError]);

  const beforeUpload = async (file: RcFile) => {
    const { isLessThenLimit, validFile, filePreload } = await fileProcessing(file);
    if (isLessThenLimit && validFile) {
      const formData = new FormData();
      formData.append("category", category);
      formData.append("file", filePreload);
      uploadAttachmentRequest({
        taskId,
        body: formData,
      });
    }
    return false;
  };

  return (
    <Upload name="import_file" showUploadList={false} beforeUpload={beforeUpload} multiple>
      <Button shape="circle" type="default" onClick={hide} icon={<PictureOutlined style={{ fontSize: "20px" }} />} />
    </Upload>
  );
}

export { UploadMedia };
