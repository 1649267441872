import { useCallback } from "react";

import { useUploadTaskSignatureMutation } from "core/app/api";
import { useAppDispatch } from "core/app/store";
import { dataUrlToFile } from "core/shared/tools/dataUrlToFile";
import { SignatureSide } from "core/app/api/interfaces";
import { deleteSignature, updateSignature, ITaskSignature } from "core/app/store/task-signature-slice";
import { extractErrorMessage } from "core/shared/tools/extractErrorMessage";

export function useSendSignature() {
  const dispatch = useAppDispatch();
  const [request] = useUploadTaskSignatureMutation();

  const sendSignature = useCallback(
    async (signature: ITaskSignature) => {
      if (signature) {
        try {
          const file = await dataUrlToFile(signature.url, "signature");
          const formData = new FormData();
          if (signature.side === SignatureSide.Executor) {
            formData.append("executor_id", signature.signatoryId);
          }
          if (signature.side === SignatureSide.Customer) {
            formData.append("customer_representative_id", signature.signatoryId);
          }
          formData.append("signature", file);

          const response = await request({
            taskId: signature.taskId,
            side: signature.side,
            body: formData,
          }).unwrap();

          if (response) {
            dispatch(
              deleteSignature({
                id: signature.id,
              })
            );
          }
        } catch (e: any) {
          dispatch(
            updateSignature({
              id: signature.id,
              requestAttemptsCount: signature.requestAttemptsCount + 1,
              errorMessage: extractErrorMessage(e),
            })
          );
          console.error(e);
        }
      }
    },
    [dispatch, request]
  );

  return sendSignature;
}
