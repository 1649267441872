import { Row, Col } from "antd";
import { useEffect, useState } from "react";

import Text from "core/lib/Text/Text";
import { useNetworkState } from "core/shared/main/api/useNetworkState";
import { ITaskSignature } from "core/app/store/task-signature-slice";
import { useCurrentStep } from "core/widgets/AssistanceCarTaskModal/hooks/useCurrentStep";
import { useGetEmployeeSignatureQuery } from "core/app/api";

import { EtoTaskSignatureSide } from "../../../interfaces";

interface SignatureCompletedProps {
  side: EtoTaskSignatureSide;
  taskId: number;
  localSignature?: ITaskSignature;
}

function SignatureCompleted({ side, taskId, localSignature }: SignatureCompletedProps) {
  const { isOnline } = useNetworkState();
  const step = useCurrentStep();
  const [signatureSrc, setSignatureSrc] = useState<string | undefined>();
  const signatureId =
    side === EtoTaskSignatureSide.SENIOR_ELECTRICIAN
      ? Number(step?.mechanic_signature_id)
      : Number(step?.driver_signature_id);
  const { data, isSuccess, isError } = useGetEmployeeSignatureQuery(signatureId, {
    skip: !signatureId,
  });

  useEffect(() => {
    if (!isOnline && localSignature) {
      setSignatureSrc(localSignature.url);
    } else if (data && isSuccess) {
      setSignatureSrc(data.signature);
    }
  }, [data, isError, isSuccess, isOnline, localSignature]);

  let sideName = "";
  if (side === EtoTaskSignatureSide.SENIOR_ELECTRICIAN) {
    sideName = "выпускающий электромеханик";
  }
  if (side === EtoTaskSignatureSide.MACHINIST) {
    sideName = "машинист";
  }
  return (
    <>
      <Row>
        <Col>
          <Text size="label1" transform="uppercase" boldness="semibold">
            {sideName}
          </Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14} style={{ alignSelf: "center" }}>
          <Row>
            <Col>
              <Text transform="uppercase" size="label4" boldness="semibold">
                {data?.executor.position}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text size="label1" boldness="black" className="blue">
                {data?.executor.full_name}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <img style={{ maxWidth: "100%", border: "1px solid #d9d9d9" }} src={signatureSrc} alt="" />
        </Col>
      </Row>
    </>
  );
}

export { SignatureCompleted };
