import { useState, useMemo } from "react";

import { AssistanceCarMaintenanceContextValues, FormPages } from "../AssistanceCarMaintenanceContext";

export function useAssistanceCarMaintenanceContextValues(): AssistanceCarMaintenanceContextValues {
  const [page, setPage] = useState(FormPages.TASK);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [signatureSide, setSignatureSide] = useState<AssistanceCarMaintenanceContextValues["signatureSide"]>(null);
  const [errorsSteps, setErrorsSteps] = useState<AssistanceCarMaintenanceContextValues["errorsSteps"]>({
    PERFORMING_ETO: 0,
    PRE_SHIFT_CHECK: 0,
    POST_SHIFT_CHECK: 0,
  });

  return useMemo(
    () => ({
      page,
      setPage,
      showErrorMessage,
      setShowErrorMessage,
      errorsSteps,
      setErrorsSteps,
      signatureSide,
      setSignatureSide,
    }),
    [page, showErrorMessage, errorsSteps, signatureSide]
  );
}
