import { Form, Select, Alert } from "antd";
import { useState } from "react";

import { useWorkTypes } from "core/shared/main/useWorkTypes";

function WorkTypeField() {
  const [description, setDescription] = useState("");
  const { data: workTypes } = useWorkTypes();
  const options =
    workTypes?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const handleSelectChange = (val: number) => {
    if (!workTypes) {
      return;
    }
    const workType = workTypes.find((item) => item.id === val);
    if (workType) {
      setDescription(workType.description);
    } else {
      setDescription("");
    }
  };

  return (
    <>
      <Form.Item label="ВИД РАБОТ" name="workTypeId" rules={[{ required: true, message: "" }]}>
        <Select
          className="equipment-type-field"
          size="large"
          allowClear
          showSearch
          loading={false}
          filterOption={(input, option) =>
            (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
          }
          options={options}
          onChange={handleSelectChange}
        />
      </Form.Item>
      {!!description && <Alert className="mb-2" message={description} type="info" />}
    </>
  );
}

export default WorkTypeField;
