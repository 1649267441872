import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function EquipmentType({ task }: FieldProps): ReactElement {
  return <Field label="Тип оборудования" value={task.equipment_type?.name} />;
}

export { EquipmentType };
