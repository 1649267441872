import { Form, Divider, Row, Col, Button, InputNumber } from "antd";
import { ReactElement, useEffect, useMemo, useState } from "react";

import dayjs from "core/shared/tools/dayjs";
import { ITask, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useTask } from "core/shared/task/useTask";
import { useSignatureStatus } from "core/shared/task/useSignatureStatus";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { useDisabled } from "core/shared/task/useDisabled";
import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";

import { SignatureForm } from "../shared/SignatureForm";
import Title from "../shared/TaskTitle";

import StateButtons, { prepareFields } from "./StateButtons";
import GeneralInfo from "./GeneralInfo";
import Body from "./Body";
import { MediaOrComment } from "./MediaOrComment";

import "./styles.scss";

/** Задание на аварийно-восстановительную работу. */
function RecoveryTaskModal(): ReactElement {
  const { data: task } = useTask();
  const [form] = Form.useForm();
  const visible = task?.type === TaskTypesIdsEnum.RECOVERY || task?.type === TaskTypesIdsEnum.SERVICE_REQUEST;
  const [signatureStatus] = useSignatureStatus();
  const disabled = useDisabled();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task);
  const [disabledByChanged, setDisabledByChanged] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const initialValues = useMemo(() => {
    if (!task) {
      return {};
    }
    const suspendedUntil = dayjs(task.suspended_until);
    return {
      ...task,
      newState: undefined,
      suspendedUntil: suspendedUntil.isValid() ? suspendedUntil : null,
      itsmEquipment: task.itsm_equipment,
      no_photo: !!task.no_photo_reason_type_id,
    };
  }, [task]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues, visible]);

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      setDisabledByChanged(true);
    }
  }, [updateTaskResult.isSuccess]);

  const handleFinish = (values: Partial<ITask>) => {
    setShowErrorMessage(false);
    const fieldsToUpdate = prepareFields(values);
    updateTaskRequest({
      ...fieldsToUpdate,
    });
  };

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };

  return (
    <div className="task-modal recovery-task-modal">
      {task && (
        <Form
          onValuesChange={() => {
            setDisabledByChanged(false);
          }}
          onFinish={handleFinish}
          onFinishFailed={handleValidationFailed}
          form={form}
          name="TaskCard"
          layout="vertical"
          initialValues={initialValues}
        >
          <Form.Item name="id" label="id" hidden>
            <InputNumber />
          </Form.Item>
          <Title icon task={task} />
          {!!signatureStatus && <SignatureForm task={task} />}
          {!signatureStatus && (
            <>
              {showErrorMessage && (
                <Col className="mb-3" span={24}>
                  <ErrorMessage message="Невозможно сохранить задачу" />
                </Col>
              )}
              <StateButtons task={task} form={form} />
              <GeneralInfo task={task} />
              <Body form={form} />
              <MediaOrComment />
              <Divider />
              <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w-100"
                    loading={updateTaskResult.isLoading}
                    disabled={disabled || disabledByChanged}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
      )}
    </div>
  );
}

export default RecoveryTaskModal;
