import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

/** Атрибут "Балансодержатель" в карточке ТО. */
function CustomerDepartment({ task }: FieldProps): ReactElement {
  return <Field label="Балансодержатель" value={task.customer_department?.name} />;
}

export { CustomerDepartment };
