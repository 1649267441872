import { createContext, useState, useMemo, useEffect } from "react";
import { Dayjs } from "dayjs";
import qs from "qs";

import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { useUnitId } from "core/shared/unit/useUnitId";

interface ShiftContextInterface {
  shiftNum: null | number;
  setShiftNum: (arg: null | number) => void;
  date: Dayjs | null;
  setDate: (date: Dayjs | null) => void;
  teamId: string;
  setTeamId: (arg: string) => void;
  queryString: string;
  showOutOfPlan: boolean;
  setShowOutOfPlan: (arg: boolean) => void;
}

const ShiftContext = createContext<ShiftContextInterface>({
  shiftNum: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShiftNum: () => {},
  date: dayjs(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDate: () => {},
  teamId: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTeamId: () => {},
  queryString: "",
  showOutOfPlan: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowOutOfPlan: () => {},
});

const useShiftContext = () => {
  const [unitId] = useUnitId();
  const [shiftNum, setShiftNum] = useState<number | null>(null);
  const [teamId, setTeamId] = useState<string>("");
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [queryString, setQueryString] = useState("");
  const [showOutOfPlan, setShowOutOfPlan] = useState(false);

  const setDateResetTeamId = (newDate: Dayjs | null) => {
    setDate(newDate);
    setTeamId("");
  };

  const contextValue = useMemo(
    () => ({
      shiftNum,
      setShiftNum,
      date,
      setDate: setDateResetTeamId,
      teamId,
      setTeamId,
      queryString,
      showOutOfPlan,
      setShowOutOfPlan,
    }),
    [date, queryString, shiftNum, teamId, showOutOfPlan]
  );

  useEffect(() => {
    if (unitId && shiftNum && date) {
      setQueryString(
        qs.stringify({
          unit_id: unitId,
          shift_num: shiftNum,
          date: date.format(apiDateFormat),
        })
      );
    }
  }, [date, shiftNum, unitId]);

  return contextValue;
};

export { useShiftContext, ShiftContext };
