import { intersection } from "lodash";

import { Role } from "core/shared/constants";

import { useUserRoles } from "../api/useUserRoles";

export function useHasUserOneOfSelectedRoles(roles: Role[]) {
  const userRoles = useUserRoles();

  return intersection(userRoles, roles).length !== 0;
}
