import { createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";

export interface IEmployeesSchedule {
  currentMonthSheduleId: number | null;
}

export const initialState: IEmployeesSchedule = {
  currentMonthSheduleId: null,
};

export const EmployeesScheduleSlice = createSlice({
  name: "employeesSchedule",
  initialState,
  reducers: {
    setCurrentMonthSheduleId(state, action: { payload: number }) {
      state.currentMonthSheduleId = action.payload;
    },
  },
});

export const { setCurrentMonthSheduleId } = EmployeesScheduleSlice.actions;
export const monthScheduleSelector = createSelector(
  (state: RootState) => state.employeesSchedule,
  (state) => state.currentMonthSheduleId
);
